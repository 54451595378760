import { Link } from '@/contexts/LanguageContext';
import { ReactNode } from 'react';

type InfoBoxLinkProps = {
  href?: string;
  internal?: boolean;
  children: ReactNode;
  className?: string;
};

export const InfoBoxLink = ({ href, internal, children, className }: InfoBoxLinkProps) => {
  if (!href) return <div className={className}>{children}</div>;

  if (internal)
    return (
      <Link href={href}>
        <a className={className}>{children}</a>
      </Link>
    );

  return (
    <a href={href} className={className}>
      {children}
    </a>
  );
};
