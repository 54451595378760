import ReactPlayer from 'react-player/youtube';
import { VideoWithConsent } from '.';

type VideoWithConsentYoutubeProps = {
  url: string;
};

export const VideoWithConsentYoutube = ({ url }: VideoWithConsentYoutubeProps) => (
  <VideoWithConsent>
    <ReactPlayer controls={true} width="100%" height="100%" url={url} />
  </VideoWithConsent>
);
