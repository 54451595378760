import { Icon, IconProps } from '@/components/ui/Icon';
import { ReactNode } from 'react';
import cn from 'classnames';
import { InfoBoxLink } from './Link';

type InfoBoxProps = {
  href?: string;
  className?: string;
  icon: IconProps['name'];
  children: ReactNode;
  internalLink?: boolean;
};

export const InfoBox = ({ href, className, icon, children, internalLink }: InfoBoxProps) => (
  <div
    className={cn(
      'flex flex-row-reverse items-center justify-between w-full space-x-5 space-x-reverse md:space-x-0 md:space-y-5 md:flex-col md:justify-start md:items-start direction-switch',
      className
    )}
  >
    <InfoBoxLink href={href} internal={internalLink} className="relative flex-shrink-0 block w-16 h-16">
      {href && <div className="absolute inset-0 w-full h-full bg-gray-900 rounded-full opacity-40 animate-ping-slow"></div>}
      <Icon name={icon} className="absolute inset-0 w-full h-full bg-white rounded-full" />
    </InfoBoxLink>

    <div className="text-lg font-bold break-words whitespace-pre-line">{children}</div>
  </div>
);
