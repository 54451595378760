import { Languages, Locale } from '@/contexts/LanguageContext';
import compact from 'lodash/compact';
import { Enum_Cluster_Type, GetAllDataQuery } from 'queries/getAllData';
import { getSiteLink } from 'utils/links/getSiteLink';
import { NavigationItemContentHamburgerMenuLinkProps } from './Link';

export const createNavigationItemContentHamburgerMenuLinks = (
  strapiLookup: GetAllDataQuery,
  lang: Languages,
  t: Locale
): Array<NavigationItemContentHamburgerMenuLinkProps> => {
  const getClusterTitle = (clusterType: Enum_Cluster_Type): string => {
    const cluster = strapiLookup.allStartPages?.find((page) => page?.cluster?.type === clusterType);

    if (!cluster) {
      console.error(
        `No cluster found for cluster type "${clusterType}". Fix this issue by removing all "getClusterTitle()" using that type`
      );

      return '';
    }

    return cluster[lang]?.title || cluster.de?.title || '';
  };

  const getSublinks = (clusterType: Enum_Cluster_Type): Omit<NavigationItemContentHamburgerMenuLinkProps, 'sublinks'>[] => {
    const subclusters = compact(strapiLookup.allSubClusters?.filter((subcluster) => subcluster?.cluster?.type === clusterType));

    return subclusters?.map((subcluster) => ({
      text: subcluster[lang]?.short_title || subcluster.de?.short_title || '',
      href: subcluster.slug ? `[lang]/${clusterType}/${subcluster.slug}` : `[lang]/${clusterType}`,
    }));
  };

  const getMiscSiteLink = (slug: string): NavigationItemContentHamburgerMenuLinkProps => {
    const miscSite = strapiLookup.allFullMiscSites?.find((site) => site?.slug === slug);

    if (!miscSite) {
      console.error(
        `Hamburger menu item with slug "${slug}" does not exist. Remove "getMiscSiteLink('${slug}') from the config items to fix this issue."`
      );

      return { text: '' };
    }

    return {
      text: miscSite[lang]?.title || miscSite.de?.title || '',
      href: `[lang]/${miscSite.slug}`,
    };
  };

  const getNavigatorTabLink = (slug: string): NavigationItemContentHamburgerMenuLinkProps => {
    const navigatorTab = strapiLookup.allStartPages?.find((page) => page?.navigator_tab?.slug === slug);

    if (!navigatorTab) {
      console.error(
        `Hamburger menu item with slug "${slug}" does not exist. Remove "getNavigatorTabLink('${slug}') from the config items to fix this issue."`
      );

      return { text: '' };
    }

    return {
      text: navigatorTab[lang]?.title || navigatorTab.de?.title || '',
      href: `[lang]/navigator/${navigatorTab.navigator_tab?.slug}`,
    };
  };

  const config: NavigationItemContentHamburgerMenuLinkProps[] = [
    { text: t.general.navigation.links.home, href: '[lang]' },
    {
      text: getClusterTitle(Enum_Cluster_Type.Finance),
      sublinks: getSublinks(Enum_Cluster_Type.Finance),
    },
    {
      text: getClusterTitle(Enum_Cluster_Type.Job),
      sublinks: getSublinks(Enum_Cluster_Type.Job),
    },
    {
      text: t.general.navigation.links.Kontakt,
      sublinks: [
        { text: strapiLookup.jcDigitalPage?.[lang]?.title || strapiLookup.jcDigitalPage?.de?.title || '', href: '[lang]/jc-digital' },
        getMiscSiteLink('kontakt'),
        getNavigatorTabLink('wissen-sie-bereits-welcher-jobcenter-standort-fuer-sie-zustaendig-ist'),
        getNavigatorTabLink('wobei-koennen-wir-ihnen-helfen'),
        getMiscSiteLink('kontaktformular'),
        getSiteLink('leichte-sprache', strapiLookup, lang),
        getMiscSiteLink('notfalladressen'),
        getSiteLink('kundinnenreaktionsmanagement-ihre-meinung-ist-uns-wichtig', strapiLookup, lang),
        getSiteLink('oeffentliche-zustellungen', strapiLookup, lang),
      ],
    },
    {
      text: t.general.navigation.links.Ueber,
      sublinks: [
        { text: strapiLookup.jcFactsPage?.[lang]?.title || strapiLookup.jcFactsPage?.de?.title || '', href: '[lang]/jc-facts' },
        getSiteLink('unsere-organisation', strapiLookup, lang),
        getSiteLink('presse-oeffentlichkeitsarbeit', strapiLookup, lang),
        getSiteLink('ueber-die-jobcenter-web-app', strapiLookup, lang),
      ],
    },
    {
      text: t.general.navigation.links.Karriere,
      sublinks: [
        getSiteLink('das-jobcenter-dortmund-als-arbeitgeber', strapiLookup, lang),
        { text: t.general.jobs, href: '[lang]/aktuelle-jobs' },
        getSiteLink('ausbildung-duales-studium-im-jobcenter', strapiLookup, lang),
      ],
    },
    getMiscSiteLink('fuer-arbeitgebende'),
  ];

  return config.map((NavigationItemContentHamburgerMenuLink) => {
    if (NavigationItemContentHamburgerMenuLink.sublinks?.length === 1)
      return {
        ...NavigationItemContentHamburgerMenuLink.sublinks[0],
        isSublink: false,
      };

    return NavigationItemContentHamburgerMenuLink;
  });
};
