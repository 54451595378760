import {
  Article as ArticleType,
  ArticleDetailsFragment,
  ComponentMiscellaneousLearningModeKeyword,
  JobDetailsFragment,
  Maybe,
  NewsDetailsFragment,
  Site,
  SiteDetailsFragment,
} from 'queries/getAllData';
import { SeoProps } from 'types';
import compact from 'lodash/compact';
import React, { FunctionComponent } from 'react';
import { Languages, Link, useLanguage } from '@/contexts/LanguageContext';
import { DownloadButton } from '../Button';
import { RelatedLinks } from '../RelatedLinks';
import { RelatedLinksItemProps } from '../RelatedLinks/Item';
import { getRelatedLinks } from 'utils/getRelatedLinks';
import { getRelatedDownloads } from 'utils/getRelatedDownloads';
import { InfoContact } from '../Info/Contact';
import { RichTextWithYoutubeVideos } from '../RichTextWithYoutubeVideos';
import PageRating from '@/components/ui/PageRating';

export type AllArticleTypes = SiteDetailsFragment | NewsDetailsFragment | JobDetailsFragment | ArticleDetailsFragment;

export type LearningModeProps =
  | Maybe<
      { __typename?: 'ComponentMiscellaneousLearningModeKeyword' | undefined } & Pick<
        ComponentMiscellaneousLearningModeKeyword,
        'Keyword' | 'Description'
      >
    >[]
  | null
  | undefined;

export type ArticleProps<T extends AllArticleTypes> = SeoProps & {
  article: T;
  lang: Languages;
  originalLanguage?: Languages | null;
  className?: string;
  learningModeData: LearningModeProps;
  type: RelatedLinksItemProps['type'];
};

export const isSite = (article: any): article is Site => {
  return typeof article.show_contact_button !== 'undefined';
};
export const isArticleOrSite = (article: any): article is Site | ArticleType => {
  return typeof article.illustration !== 'undefined';
};

export const hasIllustration = (article: AllArticleTypes): article is Site | ArticleType => {
  return isArticleOrSite(article) && Boolean(article.illustration?.media?.url);
};

export const isNotNullOrUndefined = (obj: any): obj is Exclude<any, undefined | null> => {
  return false;
};

export const Article: FunctionComponent<ArticleProps<AllArticleTypes>> = ({
  lang,
  originalLanguage,
  className,
  article,
  learningModeData,
}) => {
  const { t } = useLanguage();
  const relatedLinks = getRelatedLinks(article, lang);
  const downloads = getRelatedDownloads(article, lang);

  let { title, text } = article[lang] ?? {};
  if (!title) title = '';
  if (!text) text = '';

  if (hasIllustration(article)) {
    const paragraphMatch = text.match(/(^.*?\<\/p\>)(.*?\<\/p\>)$/);
    if (paragraphMatch) {
      const beforeLinebreaks = paragraphMatch[1];
      const afterLinebreaks = paragraphMatch[2];
      text = `
      ${beforeLinebreaks}
      <img src="${article.illustration?.media?.url}" class="h-32 ml-auto mr-8 lg:hidden" alt=""/>
      ${afterLinebreaks}`;
    }
  }

  return (
    <div className={className}>
      {hasIllustration(article) && (
        <div
          className="absolute inset-0 hidden h-40 mr-8 bg-right-bottom bg-no-repeat top-32 -z-1 lg:block"
          style={{
            backgroundImage: `url("${article.illustration?.media?.url}")`,
            backgroundSize: 'contain',
          }}
        ></div>
      )}
      {originalLanguage && (
        <div
          className="px-4 py-3 mb-4 text-blue-700 bg-blue-300 bg-opacity-50 border border-blue-500 rounded-md lg:w-3/4 direction-switch"
          role="alert"
        >
          <p className="font-bold">{t.article.disclaimer}</p>
        </div>
      )}
      <div className="flex flex-col items-start lg:flex-row">
        <div className="flex flex-col items-start lg:w-3/4 lg:pr-10 ">
          <article className="w-full mb-12 direction-switch">
            <header className="mb-10 prose prose-lg break-words">
              <h1>{title}</h1>
            </header>

            <section>
              <RichTextWithYoutubeVideos text={text} learningModeData={learningModeData} />
            </section>
          </article>
          <PageRating />
        </div>
        <div className="relative w-full lg:w-1/4 ">
          <div className="hidden mb-10 prose prose-lg lg:block">
            <h1>&nbsp;</h1>
            <span></span>
          </div>
          <div className="md:w-1/2 lg:w-full">
            {compact(article.contacts_ordered).map((contact) => {
              if (!contact?.contact) return null;
              return <InfoContact className="mb-16" contact={contact.contact} key={contact.contact.id} />;
            })}
          </div>
          <div className="flex flex-wrap -mb-3 md:-mx-5 md:-mb-5 lg:m-0">
            {isSite(article) && article.show_contact_button && (
              <div className="w-full mb-8 md:w-1/2 md:mr-1/2 md:px-3 md:mb-16 lg:px-0 lg:mr-0 lg:mb-16 lg:w-full">
                <Link href="[lang]/kontaktformular">
                  <a>
                    <div className="flex items-center justify-center px-4 py-2 bg-white border-2 border-gray-900 rounded-full ">
                      <p className="font-bold direction-switch">{t.general.contactForm}</p>
                    </div>
                  </a>
                </Link>
              </div>
            )}
            {downloads.map((download) => (
              <div className="w-full mb-3 md:w-1/2 md:px-3 md:mb-5 lg:px-0 lg:mb-3 lg:w-full">
                <DownloadButton download={download} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <RelatedLinks items={relatedLinks} className="mt-20 lg:mt-0" />
    </div>
  );
};
