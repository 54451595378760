import { Footer } from '@/components/Footer';
import { GetAllDataQuery } from 'queries/getAllData';
import React, { ReactNode } from 'react';
import { ModalSearch } from '../Modal/Search';

type LayoutProps = { children: ReactNode; strapiLookup: GetAllDataQuery };

export const Layout = ({ children, strapiLookup }: LayoutProps) => {
  return (
    <div id="Layout">
      <div className="pb-40 lg:pb-0">
        <div className="max-w-6xl px-4 pt-3 pb-24 mx-auto md:pb-40 lg:min-h-screen md:px-16 lg:px-4 md:pt-12 xxl:max-w-8xl">{children}</div>

        <Footer strapiLookup={strapiLookup} />
      </div>

      <ModalSearch />
    </div>
  );
};
