import { Layout } from '@/components/ui/Layout';
import { ModalConfirmTranslation } from '@/components/ui/Modal/ConfirmTranslation';
import { NavigationProps, Navigation } from '@/components/ui/Navigation';
import { createNavigationItemContentHamburgerMenuLinks } from '@/components/ui/Navigation/Item/Content/HamburgerMenu/createLinks';
import { NavigationItemContentHamburgerMenuLinkProps } from '@/components/ui/Navigation/Item/Content/HamburgerMenu/Link';
import { NotSupportedBrowser } from '@/components/ui/NotSupportedBrowser';
import { AsyncReturnType, replaceWasabiUrl, trimLanguageKeys } from '@/utils';
import compact from 'lodash/compact';
import { NextPage, GetServerSideProps } from 'next';
import { getDisplayName } from 'next/dist/shared/lib/utils';
import { useRouter } from 'next/router';
import { GetAllDataQuery } from 'queries/getAllData';
import React, { ReactElement } from 'react';
import { Languages, Locale, isLocale, getLocale, LanguageContextProvider } from '.';
import { NavigationContextProvider } from '../NavigationContext/Provider';
import { VideoConsentContextProvider } from '../VideoConsentContext/Provider';

type StaticHoC<T> = {
  hoc: (Page: (...args: any[]) => JSX.Element | ReactElement) => NextPage<any, T>;
  getServerSideProps: <U, P = {}>(
    // Refactor this by removing storybook and the whole monorepo consequences
    getServerSidePageProps: (strapiLookup: GetAllDataQuery, lang: Languages, params: P, t: Locale) => Promise<Omit<U, 'lang'>>,
    getLookup: (preview: boolean) => GetAllDataQuery
  ) => GetServerSideProps<{ t: Locale; lang: Languages } & NavigationProps & Omit<U, 'lang'>, { lang: Languages } & P>;
};

type LocaleProps = {
  t: Locale;
  hamburgerConfig: Array<NavigationItemContentHamburgerMenuLinkProps>;
  strapiLookup: GetAllDataQuery;
  [key: string]: any;
};

export const withLocale: StaticHoC<LocaleProps> = {
  hoc(Page) {
    const WithLocale = ({ t, hamburgerConfig, recentNews, strapiLookup, ...pageProps }: LocaleProps) => {
      const router = useRouter();
      if (router.isFallback) {
        return null;
      }
      return (
        <LanguageContextProvider t={t}>
          <NavigationContextProvider>
            <VideoConsentContextProvider>
              <NotSupportedBrowser />
              <ModalConfirmTranslation />
              <Layout strapiLookup={strapiLookup}>
                <Navigation hamburgerConfig={hamburgerConfig} recentNews={recentNews} />
                <Page {...pageProps} />
              </Layout>
            </VideoConsentContextProvider>
          </NavigationContextProvider>
        </LanguageContextProvider>
      );
    };
    WithLocale.displayName = `withLang(${getDisplayName(Page)})`;
    return WithLocale;
  },
  getServerSideProps: (getServerSidePageProps, getLookup) => async (ctx) => {
    if (!ctx.params) throw new Error('Parameters needs to be passed');
    const { lang } = ctx.params;
    if (!lang) throw new Error('Page language not provided');
    const isPreview = !!ctx.query.preview;

    const strapiLookup: GetAllDataQuery = getLookup(isPreview);
    const allSites = compact(strapiLookup.allFullSites);
    const allOverviewNews = compact(strapiLookup.allOverviewNewsArticles);
    if (!isLocale(lang) || !allSites.length) {
      return {
        notFound: true,
      };
    }

    const t = await getLocale(lang);

    let pageProps: AsyncReturnType<typeof getServerSidePageProps>;
    try {
      pageProps = await getServerSidePageProps(strapiLookup, lang, ctx.params, t);
    } catch (error: any) {
      console.error(error);
      return { notFound: true };
    }

    const hamburgerConfig = createNavigationItemContentHamburgerMenuLinks(strapiLookup, lang, t);

    return replaceWasabiUrl(
      trimLanguageKeys(
        {
          props: {
            t,
            hamburgerConfig,
            recentNews: allOverviewNews.slice(0, 4),
            lang,
            strapiLookup,
            ...pageProps,
          },
        },
        lang
      )
    );
  },
};
