import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { NextRouter } from 'next/router';

const lockScrolling = () => {
  document.body.classList.add('overflow-y-hidden', 'pointer-events-none');
};
const unlockScrolling = () => {
  document.body.classList.remove('overflow-y-hidden', 'pointer-events-none');
};

type LayoutContext = {
  lockScrolling: () => void;
  unlockScrolling: () => void;
  isSearchOpen: boolean;
  setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleSearch: () => void;
};

const LayoutContext = createContext<LayoutContext | null>(null);

export const LayoutContextProvider: FC<{ router: NextRouter }> = ({ children, router }) => {
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (isSearchOpen) setSearchOpen(false);
      unlockScrolling();
      const _paq = window['_paq'];
      if (!_paq) return;
      _paq.push(['setCustomUrl', url]);
      _paq.push(['setDocumentTitle', document.title]);
      _paq.push(['trackPageView']);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [isSearchOpen, setSearchOpen, router]);

  const toggleSearch = () => setSearchOpen((state) => !state);

  return (
    <LayoutContext.Provider value={{ lockScrolling, unlockScrolling, isSearchOpen, setSearchOpen, toggleSearch }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) throw new Error('LayoutContext must be used with LayoutContextProvider!');
  return context;
};
