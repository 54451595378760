import React, { FC } from 'react';
import { Link } from '@/contexts/LanguageContext';
import { Highlighter } from '../Highlighter/Highlighter';
import { Icon } from '../Icon';
import { relatedLinksItemTypeToBgColor } from '../RelatedLinks/Item/Content';
import { RelatedLinksItemProps } from '../RelatedLinks/Item';
import { useMatomo } from '@datapunt/matomo-tracker-react';

type SearchResultProps = {
  title: string;
  text?: string;
  type: RelatedLinksItemProps['type'];
  url: string;
  isChild?: boolean;
  searchTerm: string;
};

export const SearchResult: FC<SearchResultProps> = ({ title, text, type, url, isChild, searchTerm }) => {
  const { trackSiteSearch } = useMatomo();
  const handleOnClick = () => {
    const searchQuery = searchTerm.toLowerCase();
    if (searchQuery.length < 2) {
      return;
    }
    trackSiteSearch({ keyword: searchQuery });
  };
  return (
    <Link href={url}>
      <a className="mb-3" data-cy="searchResult" onKeyDown={handleOnClick} role="button" tabIndex={0} onClick={handleOnClick}>
        <div className="relative flex items-center rounded-lg shadow hover:shadow-active">
          {isChild && (
            <div className="absolute inset-y-0 w-10 py-2 rounded-l-lg md:w-10 lg:w-6">
              <div className="w-1/2 ml-auto border-b border-l border-gray-700 h-1/2"></div>
            </div>
          )}
          {!isChild && <div className={`w-10 absolute inset-y-0 rounded-l-lg lg:w-6 ${relatedLinksItemTypeToBgColor[type]}`}></div>}
          <div className="flex-grow px-6 py-2 ml-10 text-sm lg:ml-6 md:text-base">
            <p className="font-bold direction-switch">
              <Highlighter searchWords={[searchTerm]} textToHighlight={title} />
            </p>
            {text && (
              <p className="direction-switch">
                <Highlighter searchWords={[searchTerm]} textToHighlight={text} />
              </p>
            )}
          </div>
          <Icon name="Right Arrow" className="flex-shrink-0 w-10 h-4" />
        </div>
      </a>
    </Link>
  );
};
