export type BaseDocument = {
  _id: string; //uuid
  id: string; //uuid
  __v: number;
  created_at?: string;
  updated_at?: string;
  published_at?: string;
};

export type TranslatedDocument = {
  auto_translate?: boolean;
  auto_translated_at?: string;
};

export type ClusterContent = BaseDocument &
  TranslatedDocument & {
    title: string;
    short_title: string;
    tags: Array<string>;
  };

export type ClusterResult = BaseDocument & {
  cluster_name: string;
  type: 'finance' | 'job';
  de: ClusterContent;
  en: ClusterContent;
  tr: ClusterContent;
  fr: ClusterContent;
  bg: ClusterContent;
  ro: ClusterContent;
  ar: ClusterContent;
  ru: ClusterContent;
};

export type ArticleSearchResult = {
  _id: string;
  _index: 'articles';
  _score: number;
  _source: {
    url: string;
    title: string;
    text: string;
    type: ClusterResult['type'];
    subcluster: {
      title: string;
      type: ClusterResult['type'];
    };
  };
  _type: string;
};

export type TargetPageSearchResult = {
  _id: string;
  _index: 'sites' | 'news-articles';
  _score: number;
  _source: {
    url: string;
    title: string;
    text: string;
    type: 'targetPage';
  };
  _type: string;
};

export type LocationSearchResult = {
  _id: string;
  _index: 'locations';
  _score: number;
  _source: {
    url: string;
    title: string;
    type: 'targetPage';
  };
  _type: string;
};

type AllResults = ArticleSearchResult | TargetPageSearchResult | LocationSearchResult;

export const isArticleResult = (result: AllResults): result is ArticleSearchResult => {
  return result._index === 'articles';
};

export const isTargetPageResult = (result: AllResults): result is TargetPageSearchResult => {
  return result._index === 'sites' || result._index === 'news-articles';
};

export const isLocationResult = (result: AllResults): result is LocationSearchResult => {
  return result._index === 'locations';
};
