import { useVideoConsentContext } from '@/contexts/VideoConsentContext/hook';
import { ReactNode } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { VideoWithConsentPopup } from './Popup';

type VideoWithConsentProps = {
  children: ReactNode;
};

export const VideoWithConsent = ({ children }: VideoWithConsentProps) => {
  const { globallyAccepted, setGloballyAccepted } = useVideoConsentContext();
  const [accepted, setAccepted] = useState(false);
  const [saveChoice, setSaveChoice] = useState(true);

  const handleConsent = () => {
    setAccepted(true);
    if (!saveChoice) return;
    localStorage.setItem('youtubeVideoConsent', 'true');
    setGloballyAccepted(true);
  };

  useEffect(() => {
    if (!globallyAccepted) return;
    setAccepted(true);
  }, [globallyAccepted]);

  return (
    <div
      style={{ aspectRatio: '16/9' }}
      className="flex items-stretch bg-gray-100 before:pb-9/16 before:float-left after:table after:clear-both before:empty-content after:empty-content"
    >
      {accepted ? (
        <div className="flex flex-1">{children}</div>
      ) : (
        <VideoWithConsentPopup onConsent={handleConsent} saveChoice={saveChoice} setSaveChoice={setSaveChoice} />
      )}
    </div>
  );
};
