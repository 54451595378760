export * from './strapiFetch';
export * from './trimLanguageKeys';
export * from './replaceWasabiUrl';
export * from './shuffle';
export * from './humanFileSize';
export * from './mergeDeep';
export * from './ts-utils';
export * from './validations';
export * from './showFormError';
export * from './location';

import { ParsedUrlQuery } from 'querystring';

export const withRouterQuery = (path, query: ParsedUrlQuery) => {
  return Object.entries(query)
    .reduce((acc, [key, val]) => acc.replace(`[${key}]`, val as string), path)
    .replace(/\/\//g, '/');
};

export const withinArrayBoundaries = (index: number, arr: Array<any>) => {
  return Math.min(Math.max(index, 0), arr.length - 1);
};
