import { Languages, languages } from '@/contexts/LanguageContext';

const trimInternal = (obj, lang: Languages) => {
  if (typeof obj !== 'object' || obj === null) return obj;
  if (Array.isArray(obj)) return obj.map((val) => trimInternal(val, lang));
  let hasLanguage = Boolean(obj[lang]);
  for (const language of languages) {
    if (language === lang && hasLanguage) continue;
    if (language === 'de' && obj['de'] && !hasLanguage) {
      obj[lang] = obj[language];
      hasLanguage = true;
    }
    delete obj[language];
  }
  return Object.entries(obj).reduce((acc, [key, val]) => {
    if (key === 't') {
      return { ...acc, [key]: val };
    }
    return { ...acc, [key]: trimInternal(val, lang) };
  }, {});
};

export const trimLanguageKeys = <T>(obj: T, lang: Languages): T => {
  const copy = JSON.parse(JSON.stringify(obj ?? {}));
  return trimInternal(copy, lang);
};
