import cn from 'classnames';
import { Link } from '@/contexts/LanguageContext';
import NextLink from 'next/link';
import { RelatedLinksItemContent, relatedLinksItemTypeToBgColor } from './Content';
import { useRouter } from 'next/router';

export type RelatedLinksItemProps = {
  type: keyof typeof relatedLinksItemTypeToBgColor;
  text: string;
  url?: string;
  className?: string;
};

export type RelatedLinksItem = RelatedLinksItemProps;

export const RelatedLinksItem = ({ type, text, className, url }: RelatedLinksItemProps) => {
  const containerClass = cn('bg-white mb-4 rounded-lg shadow hover:shadow-active lg:text-center lg:mb-0', className);
  const isExternal = type === 'externalLink';
  const router = useRouter();
  const isPreview = !!router.query.preview && !isExternal;

  if (!url)
    return (
      <div className={containerClass}>
        <RelatedLinksItemContent text={text} type={type} />
      </div>
    );

  const normalizedUrl = isExternal ? 'https://' + url.replace(/https?:\/\//, '') : url;
  const ResolvedLink = isExternal ? NextLink : Link;

  return (
    <ResolvedLink href={`${normalizedUrl}${isPreview ? '?preview=true' : ''}`}>
      <a className={containerClass} target={isExternal ? '_blank' : undefined}>
        <RelatedLinksItemContent text={text} type={type} />
      </a>
    </ResolvedLink>
  );
};
