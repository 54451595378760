import { useLanguage } from '@/contexts/LanguageContext';
import cn from 'classnames';
import { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import { buttonInvertedStyles } from '../Button';
import { FormCheckbox } from '../Form/Checkbox';

type VideoWithConsentPopupProps = {
  onConsent: MouseEventHandler<HTMLButtonElement>;
  saveChoice: boolean;
  setSaveChoice: Dispatch<SetStateAction<boolean>>;
};
export const VideoWithConsentPopup = ({ onConsent, saveChoice, setSaveChoice }: VideoWithConsentPopupProps) => {
  const { t } = useLanguage();

  return (
    <div className="flex items-center justify-center flex-1 p-4">
      <div className="flex flex-col items-center p-8 space-y-4 text-center bg-white rounded-lg sm:max-w-1/2">
        <div className="flex flex-col items-center space-y-2">
          <h5 className="text-xl font-bold">{t.components.videoWithConsent.popup.title}</h5>

          <p>
            {t.components.videoWithConsent.popup.description}.{' '}
            <a href="https://policies.google.com/privacy" target="_blank" className="underline">
              {t.components.videoWithConsent.popup.learnMore}
            </a>
            .
          </p>
        </div>

        <button className={cn(buttonInvertedStyles, 'font-bold')} onClick={onConsent}>
          {t.components.videoWithConsent.popup.button}
        </button>

        <FormCheckbox checked={saveChoice} onChange={() => setSaveChoice((state) => !state)}>
          {t.components.videoWithConsent.popup.checkbox}
        </FormCheckbox>
      </div>
    </div>
  );
};
