import { Link, useLanguage } from '@/contexts/LanguageContext';
import { useRouter } from 'next/router';
import { NavigationItemProps } from '.';
import { IconProps } from '../../Icon';
import { IconWithBlip } from '../../Icon/WithBlip';
import cn from 'classnames';

export const NavigationItemLink = ({ href, icon, hasPopup, ariaDescription, closeNav, dataCy }: NavigationItemProps) => {
  const { t } = useLanguage();
  const router = useRouter();
  const isActive = router.pathname === href;

  return (
    <li key={href} className="flex-shrink-0 lg:h-full lg:ml-10 lg:hidden py-2">
      <Link href={href}>
        <a
          className="h-full"
          data-cy={dataCy}
          aria-current={isActive && 'page'}
          aria-haspopup={hasPopup && 'true'}
          aria-describedby={ariaDescription(t)}
          onClick={closeNav}
          role="button"
          onKeyDown={closeNav}
          tabIndex={0}
        >
          <div className="flex flex-col gap-2 items-center">
            <IconWithBlip name={icon as unknown as IconProps['name']} />
            {isActive && <span className="sr-only">Aktuelle Seite: </span>}
            <span>{ariaDescription(t)}</span>
            <div
              className={cn('transition-all duration-300 bg-black rounded-full', {
                'w-2 h-2': isActive,
                'w-0 h-0': !isActive,
              })}
            />
          </div>
        </a>
      </Link>
    </li>
  );
};
