import { Link, useLanguage } from '@/contexts/LanguageContext';
import { ContactDetailsFragment } from 'queries/getAllData';
import { Highlighter } from '../Highlighter/Highlighter';
import { InfoBox } from './Box';

export type InfoContactProps = {
  contact: ContactDetailsFragment;
  searchWords?: (string | RegExp)[];
  className?: string;
};

export const InfoContact = ({
  contact: { show_emergency_hotline, show_locations, phone, location, first_name, last_name, department, email },
  searchWords,
  className,
}: InfoContactProps) => {
  const { t } = useLanguage();

  if (show_emergency_hotline)
    return (
      <InfoBox className={className} href={`tel:${t.general.phone_numbers.emergency_hotline}`} icon="Phone">
        {t.components.contacts.emergency_hotline}:{' '}
        <a className="underline" href={`tel:${t.general.phone_numbers.emergency_hotline}`}>
          {t.general.phone_numbers.emergency_hotline}
        </a>
      </InfoBox>
    );

  if (show_locations)
    return (
      <InfoBox className={className} href="/[lang]/kontakt" internalLink={true} icon="Pin">
        {t.components.contacts.location.text}{' '}
        <Link href="[lang]/navigator/wissen-sie-bereits-welcher-jobcenter-standort-fuer-sie-zustaendig-ist">
          <a className="underline">{t.components.contacts.location.link}</a>
        </Link>
        .
      </InfoBox>
    );

  const titleItems: string[] = [];

  if (first_name || last_name) {
    const name: string[] = [];
    if (first_name) name.push(first_name);
    if (last_name) name.push(last_name);
    titleItems.push(name.join(' '));
  }

  if (department) titleItems.push(department);
  const title = titleItems.join(' | ');

  const addressItems: string[] = [];

  if (location) {
    if (location.street || location.house_number) {
      const firstLine: string[] = [];
      if (location.street) firstLine.push(location.street);
      if (location.house_number) firstLine.push(location.house_number);
      addressItems.push(firstLine.join(' '));
    }

    if (location.zip || location.city) {
      const secondLine: string[] = [];
      if (location.zip) secondLine.push(location.zip);
      if (location.city) secondLine.push(location.city);
      addressItems.push(secondLine.join(' '));
    }
  }

  const address = addressItems.join(`\n`);

  return (
    <InfoBox className={className} href={phone ? `tel:${phone}` : undefined} icon={phone ? 'Phone' : 'Info'}>
      <div>
        {searchWords ? (
          <Highlighter highlightClassName="bg-yellow-400" searchWords={searchWords} textToHighlight={title ?? ''} />
        ) : (
          <>{title}</>
        )}
      </div>

      <div>
        {searchWords ? (
          <Highlighter highlightClassName="bg-yellow-400" searchWords={searchWords} textToHighlight={address ?? ''} />
        ) : (
          <>{address}</>
        )}
      </div>

      {phone && (
        <a href={`tel:${phone}`} className="block underline">
          {phone}
        </a>
      )}

      {email && (
        <a href={`mailto:${email}`} className="block mt-1 text-sm font-normal underline">
          {email}
        </a>
      )}
    </InfoBox>
  );
};
