import { FC, useState } from 'react';
import { TextOccurrence } from '@/components/ui/RichTextWithYoutubeVideos';
import { LearningModeProps } from '@/components/ui/Article';
import useBreakpoint from '@/hooks/useBreakpoint';

type DescriptorProps = {
  text: string;
  elementIndex: number;
  textOccurrence: TextOccurrence;
  learningModeData: LearningModeProps;
  lastElement: number;
  lastEnd: number;
};

const LearningModeDescriptor: FC<DescriptorProps> = ({ lastEnd, text, textOccurrence, elementIndex, learningModeData, lastElement }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const isMobile = useBreakpoint();

  function handleOverlayClose() {
    if (isOpened) {
      setIsHovering(false);
      setIsOpened(false);
    }
  }

  function handleMenuOpen() {
    if (!isOpened) {
      setIsHovering(true);
      setIsOpened(true);
    }
  }

  return (
    <>
      {elementIndex === 0 ? text.slice(0, textOccurrence.start) : text.slice(lastEnd, textOccurrence.start)}
      <span
        role="button"
        tabIndex={0}
        onKeyDown={handleMenuOpen}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        onClick={() => {
          if (isMobile) {
            handleMenuOpen();
          }
        }}
        className="text-blue-600 underline relative cursor-pointer"
      >
        {textOccurrence.text}
        {isMobile && (isHovering || isOpened) && (
          <span
            role="button"
            tabIndex={0}
            onKeyDown={handleOverlayClose}
            onClick={handleOverlayClose}
            className="z-30 fixed w-full h-full bg-gray-900 bg-opacity-75 top-0 left-0"
          ></span>
        )}
        {
          <span
            className={`bg-gray-900 text-white transition-opacity duration-500 shadow-lgb z-40 ${
              isHovering || isOpened ? 'opacity-100' : 'opacity-0 -z-1 pointer-events-none'
            } ${isMobile ? 'fixed w-full bottom-0 left-0 rounded-t-2xl p-2' : 'w-96 absolute top-8 p-8 left-1 rounded-lg'}`}
          >
            {isMobile && <span className="w-48 h-2 rounded-full bg-gray-500 mx-auto mb-2 block"></span>}
            <span className="text-lg font-bold">{textOccurrence.text}</span>
            <br />
            <span className="text-gray-600">{learningModeData!.find((s) => s!.Keyword === textOccurrence.text)!.Description}</span>
          </span>
        }
      </span>
      {elementIndex === lastElement && text.slice(textOccurrence.end)}
    </>
  );
};

export default LearningModeDescriptor;
