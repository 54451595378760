import invertFn from 'lodash/invert';

export type Unpacked<T> = T extends (infer U)[] ? U : T;
export type NotMaybe<T> = Exclude<T, undefined | null>;
export type Compact<T extends any[] | undefined | null> = Array<NotMaybe<Unpacked<T>>>;
export type AsyncReturnType<T extends (...args: any) => any> = T extends (...args: any) => Promise<infer U>
  ? U
  : T extends (...args: any) => infer U
  ? U
  : any;
// Absolute Magie
// https://stackoverflow.com/a/56416192/5826761
type AllValues<T extends Record<PropertyKey, PropertyKey>> = {
  [P in keyof T]: { key: P; value: T[P] };
}[keyof T];
type InvertResult<T extends Record<PropertyKey, PropertyKey>> = {
  [P in AllValues<T>['value']]: Extract<AllValues<T>, { value: P }>['key'];
};
export function invert<T extends Record<PropertyKey, PropertyKey>>(obj: T) {
  return invertFn(obj) as InvertResult<T>;
}
