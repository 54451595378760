import { Context, Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

export type VideoConsentConfig = {
  globallyAccepted: boolean;
  setGloballyAccepted: Dispatch<SetStateAction<boolean>>;
};

export const VideoConsentDefault: VideoConsentConfig = {
  globallyAccepted: false,
  setGloballyAccepted: () => {},
};

export const VideoConsentContext: Context<VideoConsentConfig> = createContext(VideoConsentDefault);
