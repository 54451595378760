import parse from 'html-react-parser';
import { Children } from 'react';
import { VideoWithConsentYoutube } from './VideoWithConsent/Youtube';
import { useLearningMode } from '@/contexts/LearningModeContext/LearningModeContext';
import { LearningModeProps } from '@/components/ui/Article';
import LearningModeDescriptor from '@/components/ui/LearningMode/Descriptor';
import { ArticleLink } from '@/components/ui/Article/ArticleLink';

type RichTextWithYoutubeVideosProps = {
  text: string;
  learningModeData: LearningModeProps;
};

type DOMNode = {
  name: string;
  children: DOMNode[];
  type: string;
  attribs: { [key: string]: any };
};

type Section = {
  styled: boolean;
  children: Array<string | JSX.Element>;
};
export type TextOccurrence = {
  text: string;
  start: number;
  end: number;
};

function gatherOccurrences(textData: string, learningModeData: LearningModeProps) {
  const occurrences: TextOccurrence[] = [];
  for (let i = 0; i < (learningModeData?.length ?? 0); i++) {
    const data = learningModeData![i];

    const keyword = data?.Keyword ?? '';
    if (keyword.length === 0) continue;

    let lastIndex = textData.toLowerCase().indexOf(keyword.toLowerCase());
    while (lastIndex !== -1) {
      const text = textData.slice(lastIndex, lastIndex + keyword.length);
      occurrences.push({
        text,
        start: lastIndex,
        end: lastIndex + keyword.length,
      });
      lastIndex = textData
        .slice(lastIndex)
        .toLowerCase()
        .indexOf(keyword.toLowerCase(), lastIndex + keyword.length);
    }
  }
  return occurrences.sort((a, b) => a.start - b.start);
}

function firstWithData(data: any) {
  const children = data?.children ?? [];
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (child?.data) {
      return child.data;
    } else if (child?.children) {
      const result = firstWithData(child);
      if (result) return result;
    } else {
      return null;
    }
  }
}

export const RichTextWithYoutubeVideos = ({ text, learningModeData }: RichTextWithYoutubeVideosProps) => {
  const [learningModeEnabled] = useLearningMode();

  const textWithConvertedVideos = parse(text, {
    replace: (node: any) => {
      const { name, children }: DOMNode = node;

      if (learningModeEnabled && node.type === 'text') {
        const toReplace = gatherOccurrences(node.data, learningModeData);
        if (toReplace.length === 0) return node;
        return (
          <>
            {toReplace.map((occurrence, i) => {
              return (
                <LearningModeDescriptor
                  lastEnd={i > 0 ? toReplace[i - 1].end : -1}
                  learningModeData={learningModeData}
                  lastElement={toReplace.length - 1}
                  elementIndex={i}
                  textOccurrence={occurrence}
                  text={node.data}
                />
              );
            })}
          </>
        );
      }

      if ((node?.name ?? '') === 'a') {
        const href = node.attribs.href;
        const target = node.attribs.target;
        const rel = node.attribs.rel;
        const text = firstWithData(node);
        return <ArticleLink href={href} text={text} target={target} rel={rel} />;
      }

      if (name !== 'figure') return;
      const oembed = children.find((childNode) => childNode.name === 'oembed');
      if (!oembed || !oembed.attribs.url) return;
      const url = oembed.attribs.url.replace('youtube.com', 'youtube-nocookie.com');
      return <VideoWithConsentYoutube url={url} />;
    },
  });

  const sections: Section[] = [];

  Children.forEach(textWithConvertedVideos, (child) => {
    if (typeof child !== 'string' && (child.type === VideoWithConsentYoutube || child.type === ArticleLink)) {
      sections.push({
        styled: false,
        children: [child],
      });

      return;
    }

    const lastSectionIndex = sections.length - 1;

    if (sections.length > 0 && sections[lastSectionIndex].styled) {
      const lastSection = sections[lastSectionIndex];
      lastSection.children.push(child);
      return;
    }

    sections.push({
      styled: true,
      children: [child],
    });
  });

  if (sections.length === 0) return null;

  return (
    <div className="space-y-10">
      {sections.map((section, index) => {
        if (section.styled)
          return (
            <div className="prose prose-lg" key={index}>
              {section.children}
            </div>
          );
        return <div key={index}>{section.children}</div>;
      })}
    </div>
  );
};
