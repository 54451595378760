import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import cn from 'classnames';
import {
  AllArticleLinksFragment,
  AllExternalLinksFragment,
  AllMiscLinksFragment,
  AllSiteLinksFragment,
  DownloadContentFragment,
} from 'queries/getAllData';
import { Icon } from '../Icon';
import { Link, useLanguage } from '@/contexts/LanguageContext';

export const buttonStyles =
  'w-full px-4 py-2 bg-white border-2 border-gray-900 rounded-full direction-switch disabled:opacity-25 disabled:cursor-not-allowed';

export const buttonInvertedStyles =
  'w-full px-4 py-2 bg-gray-900 text-white border-2 border-gray-900 rounded-full direction-switch disabled:opacity-25 disabled:cursor-not-allowed';

export const Button = React.forwardRef<HTMLButtonElement, DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>(
  ({ children, className, ...buttonProps }, ref) => {
    return (
      <button className={cn(buttonStyles, className)} {...buttonProps} ref={ref}>
        {children}
      </button>
    );
  }
);

type CustomButtonProps = {
  text?: string;
  className?: string;
};
export const DownloadButton: FC<{ download: DownloadContentFragment } & CustomButtonProps> = ({ download, text, className }) => {
  return (
    <a href={download.media?.url ?? '#'} target="_blank">
      <div className={cn(buttonStyles, className)}>
        <div className="flex items-center justify-between px-3 space-x-3">
          <p className="font-bold break-words">{text || download.title}</p>
          <Icon name="Download" className="w-5 h-5" />
        </div>
      </div>
    </a>
  );
};

export const ArticleButton: FC<{ article: AllArticleLinksFragment } & CustomButtonProps> = ({ article, text, className }) => {
  const { lang } = useLanguage();
  return (
    <Link href={`/[lang]/articles/${article.slug}`}>
      <a>
        <div className={cn(buttonStyles, className)}>
          <p className="ml-3 font-bold">{text || article?.[lang]?.short_title}</p>
        </div>
      </a>
    </Link>
  );
};

export const ExternalButton: FC<{ link: AllExternalLinksFragment } & CustomButtonProps> = ({ link, text, className }) => {
  const { lang } = useLanguage();
  return (
    <a href={link.url ?? '#'} target="_blank">
      <div className={cn(buttonStyles, className, 'relative')}>
        <p className="ml-3 font-bold">{text || link?.[lang]?.title}</p>
        <Icon name="External Link" className="absolute inset-y-0 w-5 h-5 my-auto right-4" />
      </div>
    </a>
  );
};

export const SiteButton: FC<{ site: AllSiteLinksFragment } & CustomButtonProps> = ({ site, text, className }) => {
  const { lang } = useLanguage();
  return (
    <Link href={`/[lang]/site/${site.slug}`}>
      <a>
        <div className={cn(buttonStyles, className)}>
          <p className="ml-3 font-bold">{text || site?.[lang]?.short_title}</p>
        </div>
      </a>
    </Link>
  );
};

export const MiscSiteButton: FC<{ site: AllMiscLinksFragment } & CustomButtonProps> = ({ site, text, className }) => {
  const { lang } = useLanguage();
  return (
    <Link href={`/[lang]/${site.slug}`}>
      <a>
        <div className={cn(buttonStyles, className)}>
          <p className="ml-3 font-bold">{text || site?.[lang]?.title}</p>
        </div>
      </a>
    </Link>
  );
};
