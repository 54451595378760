import React, { FunctionComponent, createContext, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import * as Locales from './strings';
import NextLink from 'next/link';
import { withRouterQuery } from '@/utils';

export type Languages = keyof typeof Locales;
export type Locale = typeof Locales[Languages];
export const languages: Languages[] = ['de', 'en', 'tr', 'fr', 'bg', 'ro', 'ar', 'uk', 'ru'];
type LanguageContext = {
  languages: Languages[];
  t: Locale;
  lang: Languages;
};

const LanguageContext = createContext<LanguageContext>({ languages } as LanguageContext);

export function getInitialLocale(): Languages {
  const localSetting = localStorage.getItem('jc_language');
  if (localSetting && isLocale(localSetting)) {
    return localSetting;
  }

  return 'de';
}

export const getLocale = async (lang: Languages) => (await import('./strings'))[lang];

export function isLocale(lang: any): lang is Languages {
  return !Array.isArray(lang) && languages.includes(lang);
}

export const LanguageContextProvider: FunctionComponent<{ t: Locale }> = ({ t, children }) => {
  const router = useRouter();
  const { lang } = router.query;
  if (!lang || !isLocale(lang)) throw new Error('Invalid locale passed');
  useEffect(() => {
    document.documentElement.lang = lang;
  }, [lang]);
  return <LanguageContext.Provider value={{ languages, t, lang }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) throw new Error('LanguageContext must be used with LanguageContextProvider!');
  return context;
};

export const Link = ({ href, ...nextLinkProps }) => {
  const { query } = useRouter();
  const withQueryHref = withRouterQuery(href, query);
  return <NextLink {...nextLinkProps} href={`/${withQueryHref}`.replace(/\/\//g, '/')} />;
};
