import { RelatedLinksItemProps } from '@/components/ui/RelatedLinks/Item';
import { Languages } from '@/contexts/LanguageContext';
import compact from 'lodash/compact';
import {
  AllArticleLinksFragment,
  AllExternalLinksFragment,
  AllMiscLinksFragment,
  AllSiteLinksFragment,
  ComponentLinksExternalLinksOrdered,
  Maybe,
} from 'queries/getAllData';

type Entity = {
  article_links?: Maybe<Array<Maybe<{ __typename?: 'Article' } & AllArticleLinksFragment>>>;
  site_links?: Maybe<Array<Maybe<{ __typename?: 'Site' } & AllSiteLinksFragment>>>;
  external_links?: Maybe<Array<Maybe<ExternalLink>>>;
  external_links_ordered?: Maybe<Array<Maybe<ComponentLinksExternalLinksOrdered>>>;
  miscellaneous_links?: Maybe<Array<Maybe<{ __typename?: 'MiscellaneousSite' } & AllMiscLinksFragment>>>;
  [key: string]: any;
};

type ExternalLink = { __typename?: 'ExternalLink' } & AllExternalLinksFragment;

export const getRelatedLinks = (entity: Entity, lang: Languages) => {
  const relatedLinks: RelatedLinksItemProps[] = [];

  if ('article_links' in entity && entity.article_links) {
    const { article_links } = entity;
    const items = compact(article_links);

    const relatedArticles = items.map(
      (item): RelatedLinksItemProps => ({
        type: item.subcluster?.cluster?.type || 'targetPage',
        text: item[lang]?.short_title || item['de']?.short_title || '',
        url: `[lang]/articles/${item.slug}`,
      })
    );

    relatedLinks.push(...relatedArticles);
  }

  if ('site_links' in entity && entity.site_links) {
    const { site_links } = entity;
    const items = compact(site_links);

    const relatedArticles = items.map(
      (item): RelatedLinksItemProps => ({
        type: 'targetPage',
        text: item[lang]?.short_title || item['de']?.short_title || '',
        url: `[lang]/site/${item.slug}`,
      })
    );

    relatedLinks.push(...relatedArticles);
  }

  if (('external_links' in entity && entity.external_links) || ('external_links_ordered' in entity && entity.external_links_ordered)) {
    const items: ExternalLink[] = [];

    if ('external_links' in entity && entity.external_links) {
      const { external_links } = entity;
      const stripped = compact(external_links);
      items.push(...stripped);
    }

    if ('external_links_ordered' in entity && entity.external_links_ordered) {
      const { external_links_ordered } = entity;
      const stripped = compact(external_links_ordered.map((node) => node?.external_link));
      items.push(...stripped);
    }

    const relatedExternalLinks = items.map(
      (item): RelatedLinksItemProps => ({
        type: 'externalLink',
        text: item[lang]?.title || item['de']?.title || '',
        url: item.url || '#',
      })
    );

    relatedLinks.push(...relatedExternalLinks);
  }

  if ('miscellaneous_links' in entity && entity.miscellaneous_links) {
    const { miscellaneous_links } = entity;
    const items = compact(miscellaneous_links);

    const relatedMiscLinks = items.map(
      (item): RelatedLinksItemProps => ({
        type: 'targetPage',
        text: item[lang]?.title || item['de']?.title || '',
        url: `[lang]/${item.slug}`,
      })
    );

    relatedLinks.push(...relatedMiscLinks);
  }

  return relatedLinks;
};
