import { Icon, IconProps } from '.';

export type IconWithBlipProps = {
  showBlip?: boolean;
  onClick?: () => void;
};

export const IconWithBlip = ({ showBlip = false, onClick, ...props }: IconWithBlipProps & IconProps) => (
  <div className="relative" role="button" onKeyDown={onClick} tabIndex={0} onClick={onClick}>
    <Icon {...props} />

    {showBlip && (
      <div
        className="absolute w-2 h-2 rounded-full"
        style={{
          backgroundColor: 'red',
          top: '0.125em',
          right: '0.125em',
        }}
      />
    )}
  </div>
);
