import { Icon } from '@/components/ui/Icon';
import { withRouterQuery } from '@/utils';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { NavigationItemProps } from '../..';
import cn from 'classnames';
import { Link } from '@/contexts/LanguageContext';

export type NavigationItemContentHamburgerMenuLinkProps = {
  href?: string;
  text: string;
  sublinks?: Array<Omit<NavigationItemContentHamburgerMenuLinkProps, 'sublinks'>>;
  isSublink?: boolean;
};

export const NavigationItemContentHamburgerMenuLink = ({
  href,
  text,
  sublinks,
  isSublink,
  closeNav,
}: NavigationItemContentHamburgerMenuLinkProps & Pick<NavigationItemProps, 'closeNav'>) => {
  const router = useRouter();
  const [sublinksOpen, setSublinksOpen] = useState(false);
  const toggleSublinks = () => setSublinksOpen(!sublinksOpen);

  const isActive = Boolean(href) && withRouterQuery(router.pathname, router.query) === withRouterQuery(href, router.query);

  if (text === '') return null;

  return (
    <li
      key={href}
      className={cn({
        'mb-5 text-xl font-bold lg:mb-8 last:mb-0': !isSublink,
        'text-base font-normal mb-4 last:mb-0 first:mt-4 lg:first:mt-6': isSublink,
      })}
    >
      {!sublinks && href && (
        <Link href={href} key={href}>
          <a
            aria-current={isActive && 'page'}
            onKeyDown={closeNav}
            role="button"
            tabIndex={0}
            onClick={closeNav}
            key={href}
            className={isSublink ? 'h-12' : 'h-13'}
          >
            {isActive && (
              <span key={href} className="sr-only">
                Aktuelle Seite:{' '}
              </span>
            )}
            <div key={href} className="relative group hover:font-bold transition-all duration-200 flex gap-4 items-center">
              {text}{' '}
              {isSublink && <div className="w-0 h-0 transition-all duration-300 group-hover:w-2 group-hover:h-2 bg-black rounded-full" />}
            </div>
          </a>
        </Link>
      )}

      {sublinks && (
        <>
          <button key={href} className="relative block w-full text-left" onClick={toggleSublinks}>
            {text}
            {sublinks && (
              <div key={href} className={cn('absolute inset-y-0 flex items-center transform right-4', { 'rotate-180': sublinksOpen })}>
                <Icon key={href} name="Down Arrow" />
              </div>
            )}
          </button>

          <ul
            key={href}
            className={cn({
              hidden: !sublinksOpen,
              block: sublinksOpen,
            })}
          >
            {sublinks.map((sublink) => (
              <NavigationItemContentHamburgerMenuLink key={sublink.href} {...sublink} isSublink closeNav={closeNav} />
            ))}
          </ul>
        </>
      )}
    </li>
  );
};
