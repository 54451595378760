import cn from 'classnames';
import { useRef } from 'react';
import { useLanguage } from '@/contexts/LanguageContext';
import { buttonInvertedStyles } from '../../Button';
import { Icon } from '../../Icon';
import { NavigationItemProps } from '.';
import { IconWithBlip } from '../../Icon/WithBlip';

export const NavigationItemButton = (props: NavigationItemProps) => {
  const {
    href,
    icon,
    hasPopup,
    ariaDescription,
    PopupComponent,
    closeNav,
    isClosing,
    activeNavigationMenu,
    setActiveNavigationmenu,
    onClick,
    dataCy,
  } = props;

  const { t } = useLanguage();
  const isActive = activeNavigationMenu === href;
  const iconRef = useRef<HTMLButtonElement>(null);
  const RenderedIcon = typeof icon === 'function' ? icon(props) : <IconWithBlip name={icon} />;

  return (
    <li key={href} className="flex-shrink-0 lg:h-full lg:ml-10 lg:first:ml-0 py-2">
      <button
        key={href}
        className="h-full"
        data-cy={dataCy}
        aria-expanded={hasPopup && isActive}
        aria-haspopup={hasPopup && 'true'}
        aria-describedby={ariaDescription(t)}
        onClick={(e) => {
          e.preventDefault();

          if (onClick) {
            closeNav();
            return onClick(e);
          }

          isActive ? closeNav() : setActiveNavigationmenu(href);
        }}
        ref={iconRef}
      >
        <div className="relative flex flex-col gap-2 items-center ">
          {RenderedIcon}
          <span>{ariaDescription(t)}</span>
          <div
            className={cn('transition-all duration-300 bg-black rounded-full', {
              'w-2 h-2': isActive,
              'w-0 h-0': !isActive,
            })}
          />
        </div>
      </button>

      {hasPopup && (
        <div
          id={href.substring(1)}
          className={cn('Submenu', {
            Submenu__Active: isActive,
            invisible: !isClosing && !isActive,
          })}
        >
          <div className="Submenu__Blocker"></div>

          <div className="pt-16 Submenu__Popup lg:pt-0">
            <button onClick={closeNav} className="absolute inset-0 bottom-auto flex justify-center w-full py-3 lg:hidden">
              <Icon name="Right Arrow" className="w-6 h-6 transform rotate-90" />
            </button>

            <div className="flex flex-col items-stretch w-full h-full justify-items-stretch lg:pt-32 lg:pb-40 lg:justify-center pr-2">
              <div className="flex flex-col items-stretch flex-1 px-16 overflow-auto justify-items-stretch" style={{ maxHeight: 450 }}>
                <div className="flex flex-col items-stretch flex-1 pb-4 justify-items-stretch md:w-1/2 md:mx-auto lg:pb-0 lg:w-full">
                  {PopupComponent && <PopupComponent {...props} />}
                </div>
              </div>
            </div>

            <div className="absolute hidden lg:block bottom-20 inset-x-12">
              <button
                data-cy="hamburgerMenu-close"
                onClick={() => {
                  closeNav();
                  iconRef.current?.focus();
                }}
                className={cn(buttonInvertedStyles, 'font-bold')}
              >
                {t.general.navigation.closeText}
              </button>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};
