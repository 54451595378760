import { invert } from './ts-utils';

export const JC_Locations = {
  northwest: 'Nordwest',
  north: 'Mitte-Nord',
  integration: 'Integration Point',
  northeast: 'Nordost',
  southwest: 'Südwest',
  southeast: 'Südost',
  southwall: 'Südwall',
} as const;

const JC_Locations_Inverted = invert(JC_Locations);

export const locationToFormValue = {
  [JC_Locations.northwest]: JC_Locations_Inverted[JC_Locations.northwest],
  [JC_Locations.north]: JC_Locations_Inverted[JC_Locations.north],
  [JC_Locations.integration]: JC_Locations_Inverted[JC_Locations.integration],
  [JC_Locations.northeast]: JC_Locations_Inverted[JC_Locations.northeast],
  [JC_Locations.southwest]: JC_Locations_Inverted[JC_Locations.southwest],
  [JC_Locations.southeast]: JC_Locations_Inverted[JC_Locations.southeast],
  [JC_Locations.southwall]: JC_Locations_Inverted[JC_Locations.southwall],
};

export const zipToLocationMap = {
  44357: JC_Locations.northwest,
  44359: JC_Locations.northwest,
  44369: JC_Locations.northwest,
  44147: JC_Locations.northwest,
  44137: JC_Locations.southwest,
  44149: JC_Locations.southwest,
  44379: JC_Locations.southwest,
  44388: JC_Locations.southwest,
  44227: JC_Locations.southwest,
  44225: JC_Locations.southwest,
  44139: JC_Locations.southeast,
  44229: JC_Locations.southeast,
  44265: JC_Locations.southeast,
  44267: JC_Locations.southeast,
  44269: JC_Locations.southeast,
  44263: JC_Locations.southeast,
  44141: JC_Locations.southeast,
  44287: JC_Locations.southeast,
  44289: JC_Locations.southeast,
  44143: JC_Locations.southeast,
  44135: JC_Locations.north,
  44145: JC_Locations.north,
  44309: JC_Locations.northeast,
  44319: JC_Locations.northeast,
  44328: JC_Locations.northeast,
  44329: JC_Locations.northeast,
  44339: JC_Locations.northeast,
};
