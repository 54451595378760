import { ReactNode, useEffect, useState } from 'react';
import { VideoConsentContext } from '.';

type VideoConsentContextProviderProps = { children: ReactNode };

export const VideoConsentContextProvider = ({ children }: VideoConsentContextProviderProps) => {
  const [globallyAccepted, setGloballyAccepted] = useState(false);

  useEffect(() => {
    const youtubeVideoConsent = localStorage.getItem('youtubeVideoConsent');
    if (!youtubeVideoConsent || !JSON.parse(youtubeVideoConsent)) return;
    setGloballyAccepted(true);
  }, []);

  return <VideoConsentContext.Provider value={{ globallyAccepted, setGloballyAccepted }}>{children}</VideoConsentContext.Provider>;
};
