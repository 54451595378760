import { isLocale, Languages } from '@/contexts/LanguageContext';

export const getUserLocale = (): Languages | undefined => {
  const extractLangCode = (browserLocales: readonly string[]) =>
    browserLocales.map((locale) => {
      const trimmedLocale = locale.trim();
      return trimmedLocale.split(/-|_/)[0];
    });

  const userLocale = localStorage.getItem('jc_language');

  if (!userLocale || !isLocale(userLocale)) {
    const browserLocales = navigator.languages || navigator.language;
    if (!browserLocales) return undefined;
    const languageCodes = extractLangCode(browserLocales);
    const browserLocale = languageCodes.find((locale) => isLocale(locale));
    if (!browserLocale || !isLocale(browserLocale)) return undefined;
    localStorage.setItem('jc_language', browserLocale);
    return browserLocale;
  }

  return userLocale;
};
