import React, { FC, FunctionComponent, MouseEventHandler } from 'react';
import { NavigationControlledProps, NavigationProps } from '..';
import { IconProps } from '../../Icon';
import { NavigationItemLink } from './Link';
import { NavigationItemButton } from './Button';
import { Locale } from '@/contexts/LanguageContext';

export type NavigationItemProps = {
  href: string;
  icon: IconProps['name'] | FC<NavigationProps & NavigationItemProps>;
  hasPopup: boolean;
  ariaDescription: (t: Locale) => string;
  PopupComponent?: FunctionComponent<any>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  dataCy: string;
} & NavigationProps &
  NavigationControlledProps;

export const NavigationItem = (props: NavigationItemProps) => {
  const { href, hasPopup } = props;
  const Item = hasPopup ? NavigationItemButton : NavigationItemLink;
  return <Item key={href} {...props} />;
};
