import { useEffect, useState } from 'react';
import { Modal } from '.';
import { buttonInvertedStyles } from '../Button';
import { Icon } from '../Icon';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { getLocale, Languages, useLanguage } from '@/contexts/LanguageContext';
import { withRouterQuery } from '@/utils';
import { getUserLocale } from 'utils/getUserLocale';

type Translations = {
  title: string;
  description: string;
  primaryButton: string;
  secondaryButton: string;
};

export const ModalConfirmTranslation = () => {
  const router = useRouter();
  const { lang } = useLanguage();
  const [visible, setVisible] = useState<boolean>(false);
  const [userLang, setUserLang] = useState<Languages | null>(null);
  const [translations, setTranslations] = useState<Translations | null>(null);

  useEffect(() => {
    const userLocale = getUserLocale();
    if (!userLocale || userLocale === lang) return;
    setUserLang(userLocale);
    setVisible(true);
  }, []);

  useEffect(() => {
    if (!userLang) return;

    (async () => {
      const userLangTranslations = await getLocale(userLang);
      const siteLangTranslations = await getLocale(lang);

      setTranslations({
        title: userLangTranslations.components.modal.confirmTranslation.title,
        description: userLangTranslations.components.modal.confirmTranslation.description,
        primaryButton: userLangTranslations.components.modal.confirmTranslation.primaryButton,
        secondaryButton: siteLangTranslations.components.modal.confirmTranslation.secondaryButton,
      });
    })();
  }, [userLang]);

  if (!userLang || !translations) return null;

  const switchToUserLang = () => {
    router.replace(
      withRouterQuery(router.pathname, {
        ...router.query,
        lang: userLang,
      })
    );

    setVisible(false);
    localStorage.setItem('jc_language', userLang);
  };

  const keepUsingSiteLang = () => {
    setVisible(false);
    localStorage.setItem('jc_language', lang);
  };

  return (
    <Modal visible={visible} onClose={keepUsingSiteLang}>
      <div className="flex flex-col items-center self-center w-full max-w-xl p-8 mx-auto space-y-8 text-center bg-white rounded-lg">
        <div className="flex flex-col items-center space-y-4">
          <Icon name="Globe" className="w-10 h-10" />
          <h3 className="text-xl font-bold">{translations.title}</h3>
        </div>

        <p className="text-lg text-gray-600">{translations.description}</p>

        <div className="flex flex-col items-center self-stretch space-y-4 sm:self-center">
          <button className={cn(buttonInvertedStyles, 'font-bold')} onClick={switchToUserLang}>
            {translations.primaryButton}
          </button>

          <button className="w-full px-4 py-2 font-bold underline" onClick={keepUsingSiteLang}>
            {translations.secondaryButton}
          </button>
        </div>
      </div>
    </Modal>
  );
};
