import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLanguage } from '@/contexts/LanguageContext';
import { useLayout } from '@/contexts/LayoutContext';
import { useDebounce } from '@/hooks/useDebounce';
import {
  ArticleSearchResult,
  isArticleResult,
  TargetPageSearchResult,
  isTargetPageResult,
  isLocationResult,
  LocationSearchResult,
} from 'types';
import cn from 'classnames';
import { SearchInput } from '../Search/Input';
import { SearchCluster } from '../Search/Cluster';
import { SearchResult } from '../Search/Result';
import { Modal } from '.';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export const ModalSearch = React.memo((props) => {
  const { trackSiteSearch } = useMatomo();
  const handleOnClick = () => {
    const SearchQuery = searchTerm.toLowerCase();
    if (SearchQuery.length < 2) {
      return;
    }
    trackSiteSearch({ keyword: SearchQuery });
    console.log(SearchQuery);
  };
  const { t, lang } = useLanguage();
  const { isSearchOpen, setSearchOpen } = useLayout();

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const [searchResults, setSearchResults] = useState<Array<ArticleSearchResult | TargetPageSearchResult | LocationSearchResult>>([]);
  const sortedResults = useMemo(() => searchResults.sort((a, b) => b._score - a._score), [searchResults]);
  const [isSearching, setIsSearching] = useState(false);

  const fetchSearchResults = async (search) => {
    const searchResult = await fetch(`/api/search?query=${encodeURIComponent(search)}&lang=${lang}`);
    const text = await searchResult.text();
    try {
      return JSON.parse(text);
    } catch (e) {
      throw new Error(`Error: ${text}\n  when trying to fetch search results`);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      fetchSearchResults(debouncedSearchTerm).then(async (results) => {
        setIsSearching(false);
        setSearchResults(results);
      });
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm]);

  const bestResults = sortedResults.slice(0, 3);

  const locationResults = sortedResults.filter(isLocationResult);
  const targetPagesResults = sortedResults.filter(isTargetPageResult);
  const articleResults = sortedResults.filter(isArticleResult);

  const clusteredResults: { [key: string]: ArticleSearchResult[] } = articleResults.reduce((acc, result) => {
    const { title } = result._source.subcluster;
    if (acc[title]) {
      return { ...acc, [title]: [...acc[title], result] };
    }
    return { ...acc, [title]: [result] };
  }, {});

  const directLinks = [
    { text: t.general.navigation.links.home, url: '[lang]' },
    { text: t.general.navigation.links.sublinks.Kontaktformular, url: '[lang]/kontaktformular' },
    { text: t.general.navigation.links.sublinks.Standorte, url: '[lang]/kontakt' },
    { text: t.general.news, url: '[lang]/news' },
    { text: t.home.cards.job_cluster, url: '[lang]/job' },
    { text: t.general.navigation.links.sublinks.Notfalladressen, url: '[lang]/notfalladressen' },
  ];

  return (
    <Modal
      visible={isSearchOpen}
      onClose={() => {
        setSearchOpen(false);
        handleOnClick();
      }}
    >
      <div className="flex flex-col w-full max-w-xl p-3 mx-auto text-gray-700 bg-white rounded-lg">
        <SearchInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        <div className={cn('flex flex-col mb-6 lg:block', { hidden: debouncedSearchTerm })}>
          <p className="mb-2 font-bold">{t.components.search.go_to}</p>
          <div className="flex flex-wrap -mx-2 -mb-3">
            {directLinks.map(({ url, text }) => (
              <Link key={url + text} href={url}>
                <a className="px-4 h-11 mx-2 mb-3 text-sm font-semibold bg-gray-300 hover:bg-dark-primary hover:text-white rounded-full grid place-items-center">
                  {text}
                </a>
              </Link>
            ))}
          </div>
        </div>

        {bestResults.length > 0 && (
          <div className="flex flex-col mb-6 ">
            <p className="mb-2 font-bold">{t.components.search.best_results}</p>
            {bestResults.map((result, i) => (
              <>
                <SearchResult key={i} {...result._source} searchTerm={debouncedSearchTerm} />
              </>
            ))}
          </div>
        )}

        {sortedResults.length > 3 && (
          <div className="flex flex-col mb-6 ">
            <p className="mb-2 font-bold">{t.components.search.further_results}</p>
            {[...targetPagesResults, ...locationResults].map((result, i) => (
              <>
                <SearchResult key={i} {...result._source} searchTerm={debouncedSearchTerm} />
              </>
            ))}
            {Object.keys(clusteredResults).map((clusterName, i) => (
              <>
                <SearchCluster key={i} title={clusterName} type={clusteredResults[clusterName][0]._source.type} />
                {clusteredResults[clusterName].map((result, j) => (
                  <SearchResult key={j} isChild {...result._source} searchTerm={debouncedSearchTerm} />
                ))}
              </>
            ))}
          </div>
        )}
        <div className="-mb-3" />
      </div>
    </Modal>
  );
});
