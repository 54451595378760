import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useEffect } from 'react';

type PerformanceTracerProps = {
  children: React.ReactElement;
};

const PerformanceTracer = ({ children }: PerformanceTracerProps) => {
  const { trackEvent } = useMatomo();

  useEffect(() => {
    if (window) {
      import('web-vitals').then(({ onINP, onTTFB, onCLS, onFCP, onFID, onLCP }) => {
        const trackReport = ({ name, value, rating }) => {
          trackEvent({ category: 'Performance', action: rating, name, value });
        };
        onTTFB(trackReport);
        onINP(trackReport);
        onCLS(trackReport);
        onFCP(trackReport);
        onFID(trackReport);
        onLCP(trackReport);
      });
    }
  }, []);

  return children;
};

export default PerformanceTracer;
