type ArticleLinkProps = {
  href: string;
  text: string;
  target: string;
  rel: string;
};

export const ArticleLink = ({ href, text, target, rel }: ArticleLinkProps) => {
  return (
    <a href={href} className="relative group" style={{ textDecoration: 'none' }} target={target} rel={rel}>
      {text}
      <span className="absolute w-full h-px hover:h-2 bottom-1 left-0 flex justify-center">
        <span className="w-full group-hover:w-2 group-hover:h-2 group-hover:translate-y-2 transform group-hover:rounded-full transition-all duration-500 h-px bg-black" />
      </span>
    </a>
  );
};
