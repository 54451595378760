import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type Setting = <E>(key: string, defaultValue: E) => [E, Dispatch<SetStateAction<E>>];

const useSetting: Setting = <E>(key: string, defaultValue: E) => {
  const [value, setValue] = useState<E>(defaultValue);

  useEffect(() => {
    const value = localStorage.getItem(key);
    if (value) {
      try {
        setValue(JSON.parse(value).value);
      } catch (e) {
        setValue(defaultValue);
      }
    }
  }, [key]);

  useEffect(() => {
    if (value) {
      localStorage.setItem(key, JSON.stringify({ value }));
    } else {
      localStorage.removeItem(key);
    }
  }, [key, value]);

  return [value, setValue];
};

export default useSetting;
