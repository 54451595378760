import React, { FC } from 'react';
import { ClusterResult } from 'types';
import { relatedLinksItemTypeToBgColor } from '../RelatedLinks/Item/Content';

type SearchClusterProps = {
  title: string;
  type: ClusterResult['type'];
};

export const SearchCluster: FC<SearchClusterProps> = ({ title, type }) => {
  return (
    <div className="flex items-center h-16 mb-3 rounded-lg shadow">
      <div className={`flex-shrink-0 w-10 h-full rounded-l-lg md:w-10 lg:w-6 ${relatedLinksItemTypeToBgColor[type]}`}></div>
      <div className="flex-grow px-6 py-2 text-sm md:text-base">
        <p className="font-bold direction-switch">{title}</p>
      </div>
    </div>
  );
};
