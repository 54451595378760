import React, { FunctionComponent } from 'react';

export type IconProps = {
  name:
    | 'Bell'
    | 'Burger'
    | 'Download'
    | 'Gear'
    | 'Globe'
    | 'Info'
    | 'Phone'
    | 'Pin'
    | 'Scroll down'
    | 'Upload'
    | 'Close'
    | 'Home'
    | 'Reload'
    | 'Left Arrow'
    | 'Right Arrow'
    | 'Right Arrow White'
    | 'Down Arrow'
    | 'Checkmark'
    | 'Search'
    | 'External Link';
  className?: string;
};

export const Icon: FunctionComponent<IconProps> = ({ name, className }) => {
  return <img src={`/static/Icons/${name}.svg`} className={className} alt={name} />;
};
