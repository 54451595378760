import { useNavigationConfig } from '@/hooks/useNavigationConfig';
import { ReactNode, useState } from 'react';
import { NavigationConfig, NavigationContext, SetNavigationConfig } from '.';
import { useLanguage } from '../LanguageContext';

export type NavigationContextProviderT = {
  children: ReactNode;
};

export const NavigationContextProvider = ({ children }: NavigationContextProviderT) => {
  const { t } = useLanguage();
  const { navigationConfigDefault } = useNavigationConfig();
  const [state, setState] = useState<NavigationConfig>(navigationConfigDefault);
  const setNavigationConfig: SetNavigationConfig = (fn) => setState((state) => fn(state, t));
  return <NavigationContext.Provider value={{ navigationConfig: state, setNavigationConfig }}>{children}</NavigationContext.Provider>;
};
