import { useCallback, useEffect, useState } from 'react';

/** An enum representing the breakpoints that are used by tailwind. If we customize them, we need to change them here too */
export enum Breakpoints {
  'sm-' = '(max-width: 480px)',
  sm = '(max-width: 640px)',
  md = '(max-width: 768px)',
  'cb8' = '(max-width: 919px)',
  lg = '(max-width: 1024px)',
  xl = '(max-width: 1280px)',
  '2xl' = '(max-width: 1536px)',
}

/**
 * Hook that can be used in order to check if a given tailwind breakpoint is matched.
 * @param breakpoint The breakpoint to check. Can be any of the Breakpoints enum. (`sm-`, `sm`, `md`, `cb8`, `lg`, `xl`, `2xl`)
 * @returns True if the breakpoint is matched, false otherwise.
 *
 * @example
 * ```tsx
 * const isLg = useBreakpoint(Breakpoints.lg);
 *
 * return (
 *  <div>
 *   <p>Is lg? {isLg ? 'yes' : 'no'}</p>
 * </div>
 * );
 * ```
 */
const useBreakpoint = (breakpoint: Breakpoints = Breakpoints.md): boolean => {
  const [matches, setMatches] = useState(false);

  const handleResizeEvent = useCallback(() => {
    if (window) {
      setMatches(window.matchMedia(breakpoint).matches.valueOf());
    }
  }, [breakpoint, typeof window !== 'undefined' ? window : null]);

  useEffect(() => {
    if (document) {
      window.addEventListener('resize', handleResizeEvent);
    }
    handleResizeEvent();
  }, [handleResizeEvent, typeof document !== 'undefined' ? document : null]);

  return matches;
};

export default useBreakpoint;
