import { IconNews } from '@/components/ui/Icon/News';
import { NavigationItemContentAccessibilitySettings } from '@/components/ui/Navigation/Item/Content/AccessibilitySettings';
import { NavigationItemContentHamburgerMenu } from '@/components/ui/Navigation/Item/Content/HamburgerMenu';
import { NavigationItemContentLanguagePicker } from '@/components/ui/Navigation/Item/Content/LanguagePicker';
import { NavigationItemContentNewsSidebar } from '@/components/ui/Navigation/Item/Content/NewsSidebar';
import { useLayout } from '@/contexts/LayoutContext';
import { NavigationConfig, NavigationContextResult } from '@/contexts/NavigationContext';
import { useNavigationContext } from '@/contexts/NavigationContext/hook';

export type UseNavigationConfigResult = {
  navigationConfigDefault: NavigationConfig;
  resetNavigationConfig: () => void;
} & NavigationContextResult;

export const useNavigationConfig = (): UseNavigationConfigResult => {
  const { navigationConfig, setNavigationConfig } = useNavigationContext();
  const { toggleSearch } = useLayout();

  const navigationConfigDefault: NavigationConfig = {
    topItemMobile: {
      href: '#search',
      hasPopup: true,
      icon: 'Search',
      ariaDescription: (t) => t.general.search,
      onClick: toggleSearch,
      dataCy: 'menuItem-search',
    },
    itemsMobile: [
      {
        href: '#notification',
        hasPopup: true,
        icon: IconNews,
        ariaDescription: (t) => t.general.navigation.icon.news.aria,
        PopupComponent: NavigationItemContentNewsSidebar,
        dataCy: 'menuItem-notification_mobile',
      },
      {
        href: '#language',
        hasPopup: true,
        icon: 'Globe',
        ariaDescription: (t) => t.general.navigation.icon.language.aria,
        PopupComponent: NavigationItemContentLanguagePicker,
        dataCy: 'menuItem-language_mobile',
      },
      {
        href: '#settings',
        hasPopup: true,
        icon: 'Gear',
        ariaDescription: (t) => t.general.navigation.icon.settings.aria,
        PopupComponent: NavigationItemContentAccessibilitySettings,
        dataCy: 'menuItem-settings_mobile',
      },
      {
        href: '#hamburger',
        hasPopup: true,
        icon: 'Burger',
        ariaDescription: (t) => t.general.navigation.icon.hamburger.aria,
        PopupComponent: NavigationItemContentHamburgerMenu,
        dataCy: 'menuItem-burger_mobile',
      },
    ],
    items: [
      {
        href: '/[lang]',
        hasPopup: false,
        icon: 'Home',
        ariaDescription: (t) => t.general.navigation.icon.home.aria,
        dataCy: 'menuItem-home',
      },
      {
        href: '#notification',
        hasPopup: true,
        icon: IconNews,
        ariaDescription: (t) => t.general.navigation.icon.news.aria,
        PopupComponent: NavigationItemContentNewsSidebar,
        dataCy: 'menuItem-notification',
      },
      {
        href: '#language',
        hasPopup: true,
        icon: 'Globe',
        ariaDescription: (t) => t.general.navigation.icon.language.aria,
        PopupComponent: NavigationItemContentLanguagePicker,
        dataCy: 'menuItem-language',
      },
      {
        href: '#settings',
        hasPopup: true,
        icon: 'Gear',
        ariaDescription: (t) => t.general.navigation.icon.settings.aria,
        PopupComponent: NavigationItemContentAccessibilitySettings,
        dataCy: 'menuItem-settings',
      },
      {
        href: '#hamburger',
        hasPopup: true,
        icon: 'Burger',
        ariaDescription: (t) => t.general.navigation.icon.hamburger.aria,
        PopupComponent: NavigationItemContentHamburgerMenu,
        dataCy: 'menuItem-hamburger',
      },
    ],
  };

  const resetNavigationConfig = () => {
    if (setNavigationConfig) setNavigationConfig(() => navigationConfigDefault);
  };

  return { navigationConfigDefault, resetNavigationConfig, navigationConfig, setNavigationConfig };
};
