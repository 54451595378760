const replaceInternal = (obj) => {
  if (typeof obj === 'string')
    return obj.replace(`https://s3.eu-central-1.wasabisys.com/${process.env.STRAPI_BUCKET_NAME}/`, '/api/media/');
  if (typeof obj !== 'object' || obj === null) return obj;
  if (Array.isArray(obj)) return obj.map((val) => replaceInternal(val));
  return Object.entries(obj).reduce((acc, [key, val]) => {
    return { ...acc, [key]: replaceInternal(val) };
  }, {});
};

export const replaceWasabiUrl = <T>(obj: T): T => {
  const copy = JSON.parse(JSON.stringify(obj));
  return replaceInternal(copy);
};
