import cn from 'classnames';
import { RelatedLinksItem, RelatedLinksItemProps } from './Item';

export type RelatedLinksProps = {
  items: RelatedLinksItemProps[];
  className?: string;
};

// new version with grid
export const RelatedLinks = ({ items, className }: RelatedLinksProps) => (
  <div className={cn('-mb-4 lg:mb-0 grid md:grid-cols-3 lg:grid-cols-4 flex-wrap gap-5 auto-rows-fr', className)}>
    {items.map((item, index) => (
      <RelatedLinksItem key={index} {...item} className="md:w-auto lg:min-w-auto" />
    ))}
  </div>
);
