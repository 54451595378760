import { Link, useLanguage } from '@/contexts/LanguageContext';
import { GetAllDataQuery } from 'queries/getAllData';
import { Fragment } from 'react';
import { getSiteLink } from 'utils/links/getSiteLink';

type FooterProps = { strapiLookup: GetAllDataQuery };

export const Footer = ({ strapiLookup }: FooterProps) => {
  const { lang } = useLanguage();
  const links = [
    getSiteLink('impressum', strapiLookup, lang),
    getSiteLink('datenschutz', strapiLookup, lang),
    getSiteLink('leichte-sprache', strapiLookup, lang),
  ];

  return (
    <footer className="flex justify-center px-4 lg:justify-end lg:pl-0 lg:pr-9 lg:pb-4 lg:-mt-12">
      <div className="flex space-x-4">
        {links.map(({ href, text }, index) => {
          if (!href) return null;
          const first = index === 0;

          return (
            <Fragment key={index}>
              {!first && <div>|</div>}

              <Link href={href}>
                <a>{text}</a>
              </Link>
            </Fragment>
          );
        })}
      </div>
    </footer>
  );
};
