import { Icon } from '@/components/ui/Icon';
import { ChangeEventHandler, ReactNode } from 'react';

type FormCheckboxProps = {
  children: ReactNode;
  id?: string;
  required?: boolean;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const FormCheckbox = ({ children, id, required, checked, onChange }: FormCheckboxProps) => (
  <label htmlFor={id} className="flex mt-8 mb-8 cursor-pointer select-none">
    <div className="relative flex-shrink-0 inline-block w-5 h-5 mr-2 border-2 border-black focus-within:border-yellow-500">
      <input id={id} type="checkbox" className="absolute opacity-0" required={required} checked={checked} onChange={onChange} />

      <div className="absolute inset-0">
        <Icon name="Checkmark" />
      </div>
    </div>

    {children}
  </label>
);
