import { mergeDeep } from '@/utils';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { FunctionComponent } from 'react';
import cn from 'classnames';
import { hasIllustration } from './ui/Article';

const mobileCluster = {
  name: 'Mobile-Cluster-targetPage.svg',
  backgroundPosition: 'bottom right',
  height: '812px',
};

const mobileArticlePages = ({ type }) => ({
  name: `Mobile-Artikelseiten-${type}.svg`,
  height: '812px',
  backgroundPosition: 'bottom right',
  secondImage: {
    backgroundPosition: 'top left',
    position: {
      top: '1600px',
    },
    name: `Mobile-Artikelseiten-${type}-Bottom.svg`,
  },
});

const mobileImageMap = {
  '/[lang]/articles/[slug]': mobileArticlePages,
  '/[lang]/aktuelle-jobs': mobileCluster,
  '/[lang]/aktuelle-jobs/[slug]': mobileCluster,
  '/[lang]/news': mobileCluster,
  '/[lang]/news/[slug]': mobileCluster,

  '/[lang]/site/[slug]': mobileArticlePages,
  '/[lang]/[clusterType]': ({ clusterType }) => ({
    name: `Mobile-Cluster-${clusterType}.svg`,
    backgroundPosition: 'bottom right',
    height: '812px',
  }),
  '/[lang]/fuer-arbeitgebende': mobileArticlePages({ type: 'targetPage' }),
  '/[lang]': {
    name: 'Mobile-Home.svg',
    height: '812px',
    backgroundPosition: 'bottom right',
  },
  '/[lang]/kontakt': mobileArticlePages({ type: 'targetPage' }),
  '/[lang]/kontaktformular': {
    name: 'Mobile-Kontaktformular.svg',
    height: '812px',
    backgroundPosition: 'bottom right',
  },
  '/[lang]/dortmund-pass-beantragen': {
    name: 'Mobile-Kontaktformular.svg',
    height: '812px',
    backgroundPosition: 'bottom right',
  },
  '/[lang]/sozialticket-beantragen': {
    name: 'Mobile-Kontaktformular.svg',
    height: '812px',
    backgroundPosition: 'bottom right',
  },
  '/[lang]/navigator/[slug]': {
    name: 'Mobile-Navigator.svg',
    height: '220px',
    position: {
      top: '64px',
    },
  },
  '/[lang]/navigator/artikel/[slug]': {
    name: 'Mobile-Navigator.svg',
    height: '220px',
    position: {
      top: '64px',
    },
  },
  '/[lang]/navigator/standort': {
    name: 'Mobile-Navigator.svg',
    height: '220px',
    position: {
      top: '64px',
    },
  },
  '/[lang]/notfalladressen': mobileArticlePages({ type: 'targetPage' }),
  '/[lang]/jc-digital': mobileArticlePages({ type: 'targetPage' }),
  '/[lang]/jc-facts': mobileArticlePages({ type: 'targetPage' }),
};

const desktopCluster = {
  name: 'Cluster-targetPage.svg',
  backgroundPosition: 'top right',
  position: {
    top: `${900 - 350}px`,
  },
};

const desktopArticlePages = ({ type }) => ({
  name: `Artikelseiten-${type}.svg`,
  backgroundPosition: 'top right',
  position: {
    top: '400px',
  },
  secondImage: {
    backgroundPosition: 'top left',
    position: {
      top: '1600px',
    },
    name: `Artikelseiten-${type}-Bottom.svg`,
  },
});

const desktopImageMap = {
  '/[lang]/articles/[slug]': desktopArticlePages,
  '/[lang]/aktuelle-jobs': desktopCluster,
  '/[lang]/aktuelle-jobs/[slug]': desktopCluster,
  '/[lang]/news': desktopCluster,
  '/[lang]/news/[slug]': desktopCluster,
  '/[lang]/site/[slug]': desktopArticlePages,
  '/[lang]/[clusterType]': ({ clusterType }) => ({
    name: `Cluster-${clusterType}.svg`,
    backgroundPosition: 'bottom right',
  }),
  '/[lang]/fuer-arbeitgebende': desktopArticlePages({ type: 'targetPage' }),
  '/[lang]': {
    name: 'Home.svg',
    backgroundPosition: 'bottom right',
  },
  '/[lang]/kontakt': desktopArticlePages({ type: 'targetPage' }),
  '/[lang]/kontaktformular': {
    name: 'Kontaktformular.svg',
    backgroundPosition: 'bottom left',
  },
  '/[lang]/dortmund-pass-beantragen': {
    name: 'Kontaktformular.svg',
    backgroundPosition: 'bottom left',
  },
  '/[lang]/sozialticket-beantragen': {
    name: 'Kontaktformular.svg',
    backgroundPosition: 'bottom left',
  },
  '/[lang]/navigator/[slug]': {
    name: 'Navigator.svg',
    position: {
      top: '180px',
    },
  },
  '/[lang]/navigator/artikel/[slug]': {
    name: 'Navigator.svg',
    position: {
      top: '180px',
    },
  },
  '/[lang]/navigator/standort': {
    name: 'Navigator.svg',
    position: {
      top: '180px',
    },
  },
  '/[lang]/notfalladressen': desktopArticlePages({ type: 'targetPage' }),
  '/[lang]/jc-digital': desktopArticlePages({ type: 'targetPage' }),
  '/[lang]/jc-facts': desktopArticlePages({ type: 'targetPage' }),
};

export const getPropBasedBackgroundRules = (props) => {
  const { article } = props;
  if (article) {
    let rule = {
      dynamicVariables: {
        type: props.type,
      },
    };
    if (hasIllustration(article))
      rule = mergeDeep(rule, {
        customDesktop: {
          position: {
            top: '-1000px',
          },
        },
      });
    return rule;
  }
};

type BackgroundProps = {
  customRules?: {
    dynamicVariables?: any;
    customDesktop?: any;
    customMobile?: any;
  };
};

type BackgroundImage = {
  type: 'desktop' | 'mobile';
  name: string;
  height: string;
  backgroundPosition: string;
  position: {
    top: string;
    bottom: string;
    left: string;
    right: string;
  };
};

const BackgroundImage: FC<BackgroundImage> = React.memo(({ type, name, height, backgroundPosition, position }) => {
  if (!name) return null;
  return (
    <div
      className={cn('absolute inset-0 bg-no-repeat -z-1', {
        'hidden lg:block': type === 'desktop',
        'lg:hidden': type === 'mobile',
      })}
      style={{
        backgroundImage: `url("/static/backgrounds/${name}")`,
        height,
        backgroundPosition,
        ...position,
      }}
    ></div>
  );
});

export const Background: FC<BackgroundProps> = React.memo(({ customRules }) => {
  const router = useRouter();

  if (['/', '/404'].includes(router.pathname)) return null;

  let desktopImage = desktopImageMap[router.pathname];
  let mobileImage = mobileImageMap[router.pathname];
  if (!desktopImage || !mobileImage) return null;

  const { customDesktop, customMobile, dynamicVariables = {} } = customRules ?? {};

  if (typeof desktopImage === 'function') desktopImage = desktopImage({ ...router.query, ...dynamicVariables });
  if (typeof mobileImage === 'function') mobileImage = mobileImage({ ...router.query, ...dynamicVariables });

  if (customRules) {
    if (desktopImage && customDesktop) desktopImage = mergeDeep(desktopImage, customDesktop);
    if (mobileImage && customMobile) mobileImage = mergeDeep(mobileImage, customMobile);
  }

  return (
    <>
      <BackgroundImage type="desktop" {...desktopImage} />
      <BackgroundImage type="desktop" {...desktopImage.secondImage} />
      <BackgroundImage type="mobile" {...mobileImage} />
      <BackgroundImage type="mobile" {...mobileImage.secondImage} />
    </>
  );
});
