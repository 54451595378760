import { useMatomo } from '@datapunt/matomo-tracker-react';
import { FormEvent, useRef, useState } from 'react';
import { useLanguage } from '@/contexts/LanguageContext';
import { buttonInvertedStyles } from '@/components/ui/Button';

type AllowedEmojis = '😞' | '😐' | '😃';

const PageRating = () => {
  const { t } = useLanguage();
  const [ratedValue, setRatedValue] = useState<AllowedEmojis | null>(null);
  const [showFeedBackForm, setShowFeedbackForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const emojiOptions: readonly AllowedEmojis[] = Object.freeze(['😞', '😐', '😃'] as AllowedEmojis[]);
  const textField = useRef<HTMLTextAreaElement | null>(null);

  const { trackEvent } = useMatomo();
  const emojiDict = { '😞': -1, '😐': 0, '😃': 1 };
  const handleOnClick = (emoji) => {
    if (emoji === ratedValue) {
      return;
    }
    const articleTitle = document.title;

    const emojiValue = emojiDict[emoji];
    trackEvent({ category: 'Article Rating', action: emoji, name: articleTitle, value: emojiValue });
    if (emojiValue <= 0) {
      setShowFeedbackForm(true);
    }
  };

  const submitForm = (e: FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    const articleTitle = document.title;
    const feedback = textField.current?.value.trim();
    if (!feedback) {
      return;
    }
    // Try to send it (will fail probably)
    // @ts-ignore
    trackEvent({ category: 'Article Rating', action: 'Feedback Form', name: articleTitle, value: feedback });
  };

  return (
    <div className="grid place-items-center gap-7 py-6 my-10 md:mt-0 bg-gray-100 rounded-lg w-full">
      {!showFeedBackForm && <div>{t.components.pageRating.wasThisHelpful}</div>}
      {showFeedBackForm ? (
        formSubmitted ? (
          <p className="text-center text-lg">
            <span role="img" aria-label="Sternaugen">
              🤩
            </span>{' '}
            Vielen Dank für Ihr Feedback! Wir freuen uns sehr, dass Sie <br /> sich die Zeit genommen haben, uns Feedback zu geben.
            <br /> Nur so können wir uns weiter verbessern.
          </p>
        ) : (
          <div>
            <p className="text-lg text-center">
              Vielen Dank für Ihr Feedback!{' '}
              <span role="img" aria-label="Sternaugen">
                🤩
              </span>{' '}
              Ihre Stimme wurde mitgezählt. <br /> Warum fanden Sie den Artikel nicht hilfreich?
              {/*TODO: Translate*/}
            </p>
            <form onSubmit={submitForm} className="mt-4">
              <label htmlFor="feedbackMessage">
                <textarea
                  ref={textField}
                  name="feedbackMessage"
                  id="feedbackMessage"
                  className="w-full h-32 rounded-lg px-5 pt-3 placeholder-gray-500"
                  maxLength={200}
                  placeholder="Schreiben Sie bitte Ihre Nachricht hier (max. 300 Zeichen)." // TODO: Translate
                  required
                />
              </label>
              <div className="w-56 mx-auto mt-6">
                <button className={`${buttonInvertedStyles}`} type="submit">
                  Nachricht Abschicken {/*TODO: Translate*/}
                </button>
              </div>
            </form>
          </div>
        )
      ) : (
        <div className={`flex gap-9`}>
          {emojiOptions.map((emoji) => (
            <button
              key={emoji}
              className={`text-4xl duration-100 transition transform ${ratedValue === emoji ? 'scale-125' : ratedValue && 'grayscale'}`}
              onClick={() => {
                setRatedValue(emoji);
                handleOnClick(emoji);
              }}
            >
              {emoji}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default PageRating;
