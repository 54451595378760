export const strapiFetch = async (path) => {
  const res = await fetch(`${process.env.STRAPI_ENDPOINT}/${path}`, {
    headers: {
      Authorization: `Bearer ${process.env.STRAPI_API_TOKEN}`,
    },
  });
  const text = await res.text();
  try {
    return JSON.parse(text);
  } catch (e) {
    console.error(text);
    throw new Error(`Error: ${text}\n  when trying to fetch ${path}`);
  }
};
