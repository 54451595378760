import { NavigationItemContentHamburgerMenuLinkProps } from '@/components/ui/Navigation/Item/Content/HamburgerMenu/Link';
import { Languages } from '@/contexts/LanguageContext';
import { GetAllDataQuery } from 'queries/getAllData';

export const getSiteLink = (slug: string, strapiLookup: GetAllDataQuery, lang: Languages): NavigationItemContentHamburgerMenuLinkProps => {
  const fullSite = strapiLookup.allFullSites?.find((site) => site?.slug === slug);

  if (!fullSite) {
    console.error(
      `Hamburger menu item with slug "${slug}" does not exist. Remove "getSiteLink('${slug}') from the config items to fix this issue."`
    );

    return { text: '' };
  }

  return {
    text: fullSite[lang]?.short_title || fullSite.de?.short_title || '',
    href: `[lang]/site/${fullSite.slug}`,
  };
};
