import { Languages } from '@/contexts/LanguageContext';
import compact from 'lodash/compact';
import { AllDownloadsFragment, ComponentDownloadDownload, ComponentDownloadDownloadsOrdered, Maybe } from 'queries/getAllData';

type Entity = {
  downloads?: Maybe<Array<Maybe<Download>>>;
  downloads_ordered?: Maybe<Array<Maybe<ComponentDownloadDownloadsOrdered>>>;
  [key: string]: any;
};

type Download = { __typename?: 'Download' } & AllDownloadsFragment;

type GetRelatedDownloadsResult = ({
  __typename?: 'ComponentDownloadDownload' | undefined;
} & Pick<ComponentDownloadDownload, 'id' | 'title'>)[];

export const getRelatedDownloads = (entity: Entity, lang: Languages): GetRelatedDownloadsResult => {
  const items: Download[] = [];

  if ('downloads' in entity && entity.downloads) {
    const { downloads } = entity;
    const stripped = compact(downloads);
    items.push(...stripped);
  }

  if ('downloads_ordered' in entity && entity.downloads_ordered) {
    const { downloads_ordered } = entity;
    const stripped = compact(downloads_ordered.map((node) => node?.download));
    items.push(...stripped);
  }

  return compact(items.map((download) => download[lang] || download.de));
};
