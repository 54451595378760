import { Icon } from '../../Icon';

export const relatedLinksItemTypeToBgColor = {
  finance: 'bg-green-500',
  job: 'bg-pink-500',
  support: 'bg-yellow-500',
  request: 'bg-blue-500',
  externalLink: 'bg-gray-700',
  targetPage: 'bg-yellow-600',
  refetch: 'bg-white',
  persoenliches: 'bg-fuchsia-600',
};

export type RelatedLinksItemContentProps = {
  type: keyof typeof relatedLinksItemTypeToBgColor;
  text: string;
};

export const RelatedLinksItemContent = ({ type, text }: RelatedLinksItemContentProps) => (
  <div className="flex flex-col justify-center h-full">
    <div className={`rounded-t-lg h-6 md:h-4 ${relatedLinksItemTypeToBgColor[type]}`}></div>
    <div className="relative flex items-center flex-1">
      <p className="flex-1 p-3 font-bold text-left break-words">{text}</p>

      <Icon
        name={type === 'externalLink' ? 'External Link' : 'Right Arrow'}
        className={`${type === 'externalLink' ? 'pr-3 w-10' : 'w-6'} h-4`}
      />
    </div>
  </div>
);
