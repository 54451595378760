import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useLanguage } from '@/contexts/LanguageContext';

const IE11_DISMISS_UNSUPPORTED_KEY = 'ie11-dismissed-unsupported';

export const NotSupportedBrowser: FC = () => {
  const { t } = useLanguage();
  const [dismissedNotSupported, setDismissedNotSupported] = useState(false);
  useEffect(() => {
    const item = localStorage.getItem(IE11_DISMISS_UNSUPPORTED_KEY);
    setDismissedNotSupported(Boolean(item));
  }, []);
  return (
    <div className={cn('hidden direction-switch', { NotSupportedBrowser: !dismissedNotSupported })}>
      <div className="flex flex-col items-center text-center">
        <h1 className="text-3xl font-bold text-red-600">{t.general.notsupported.headline}</h1>
        <h2 className="max-w-3xl mb-4 text-xl">{t.general.notsupported.subline}</h2>
        <div className="flex">
          <a target="_blank" href="https://www.google.com/chrome/">
            <img src="/static/browsers/chrome.svg" alt="Chrome Logo" />
          </a>
          <a target="_blank" href="https://www.mozilla.org/">
            <img src="/static/browsers/firefox.svg" alt="Firefox Logo" />
          </a>
        </div>
        <button
          className="mt-8 text-blue-600 underline"
          onClick={() => {
            localStorage.setItem(IE11_DISMISS_UNSUPPORTED_KEY, 'true');
            setDismissedNotSupported(true);
          }}
        >
          {t.general.notsupported.continue}
        </button>
      </div>
    </div>
  );
};
