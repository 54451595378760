import cn from 'classnames';
import { Link, useLanguage } from '@/contexts/LanguageContext';
import { useRouter } from 'next/router';
import { withRouterQuery } from '@/utils';
import { NavigationItemProps } from '..';

export type NavigationItemContentLanguagePickerProps = Pick<NavigationItemProps, 'closeNav'>;

export const NavigationItemContentLanguagePicker = ({ closeNav }: NavigationItemContentLanguagePickerProps) => {
  const { query, pathname } = useRouter();
  const { languages, t, lang } = useLanguage();

  return (
    <div className="flex flex-col items-center justify-center flex-1">
      {languages.map((language) => {
        return (
          <Link
            href={withRouterQuery(pathname, {
              ...query,
              lang: language,
            })}
            key={language}
          >
            <a
              className={cn(
                'py-2 mb-4 font-bold h-11 grid place-items-center text-xl text-center rounded-full last:mb-0 hover:bg-black hover:text-white w-full',
                {
                  'bg-black text-white': language === lang,
                }
              )}
              tabIndex={0}
              role="button"
              onKeyDown={() => {
                localStorage.setItem('jc_language', language);
                if (closeNav) closeNav();
              }}
              onClick={(e) => {
                localStorage.setItem('jc_language', language);
                if (closeNav) closeNav();
              }}
            >
              {t.general.languages[language]}
            </a>
          </Link>
        );
      })}
    </div>
  );
};
