import cn from 'classnames';
import { ReactNode } from 'react';

export type ModalProps = {
  visible: boolean;
  onClose: () => void;
  children: ReactNode;
};

export const Modal = ({ visible = false, onClose, children }: ModalProps) => (
  <div
    className={cn('fixed inset-0 z-50 px-4 py-10 overflow-y-auto bg-black bg-opacity-25 fill-screen flex items-start', {
      hidden: !visible,
    })}
  >
    <button
      aria-label="Dialog schließen"
      aria-describedby="Schließt den Dialog"
      className="absolute inset-0 w-full h-full -z-1"
      onClick={onClose}
    ></button>
    {children}
  </div>
);
