import { NavigationItemProps } from '../Navigation/Item';
import { IconWithBlip } from './WithBlip';

export const IconNews: NavigationItemProps['icon'] = (props) => {
  const showBlip = typeof window !== 'undefined' && localStorage.getItem('mostRecentNewsId') !== props.recentNews[0]?.id;

  return (
    <IconWithBlip
      name="Bell"
      showBlip={showBlip}
      onClick={() => {
        localStorage.setItem('mostRecentNewsId', props.recentNews[0].id);
      }}
    />
  );
};
