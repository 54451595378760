import { NavigationItemProps } from '../..';
import { NavigationProps } from '../../..';
import { NavigationItemContentHamburgerMenuLink } from './Link';

export const NavigationItemContentHamburgerMenu = ({
  closeNav,
  hamburgerConfig,
}: NavigationProps & Pick<NavigationItemProps, 'closeNav'>) => {
  return (
    <ul className="flex-1 text-left">
      {hamburgerConfig.map((link) => (
        <NavigationItemContentHamburgerMenuLink key={link.href + link.text} {...link} closeNav={closeNav} />
      ))}
    </ul>
  );
};
