import { findAll, findChunks, sanitize } from './utils';
import { createElement, FC } from 'react';
import memoizeOne from 'memoize-one';

/**
 * This is the source from https://github.com/bvaughn/react-highlight-words
 * We want to bundle this ourselves because our bundler does a better job minifying the code
 * This also enables us to use Typescript better and make modifications if needed
 * */

type HighligherProps = {
  activeClassName?: string;
  activeIndex?: number;
  activeStyle?: JSX.IntrinsicElements['span']['style'];
  autoEscape?: boolean;
  caseSensitive?: boolean;
  className?: string;
  findChunks?: typeof findChunks;
  highlightClassName?: string | { [key: string]: string };
  highlightStyle?: JSX.IntrinsicElements['span']['style'];
  highlightTag?: JSX.Element | Function | string;
  sanitize?: typeof sanitize;
  searchWords: Array<string | RegExp>;
  textToHighlight: string;
  unhighlightClassName?: string;
  unhighlightStyle?: JSX.IntrinsicElements['span']['style'];
};

/**
 * Highlights all occurrences of search terms (searchText) within a string (textToHighlight).
 * This function returns an array of strings and <span>s (wrapping highlighted words).
 */
export const Highlighter: FC<HighligherProps> = ({
  activeClassName = '',
  activeIndex = -1,
  activeStyle,
  autoEscape,
  caseSensitive = false,
  className,
  findChunks,
  highlightClassName = '',
  highlightStyle = {},
  highlightTag = 'mark',
  sanitize,
  searchWords,
  textToHighlight,
  unhighlightClassName = '',
  unhighlightStyle,
  ...rest
}) => {
  const chunks = findAll({
    autoEscape,
    caseSensitive,
    findChunks,
    sanitize,
    searchWords,
    textToHighlight,
  });
  const HighlightTag = highlightTag;
  let highlightIndex = -1;
  let highlightClassNames = '';
  let highlightStyles;

  const lowercaseProps = (object) => {
    const mapped = {};
    for (let key in object) {
      mapped[key.toLowerCase()] = object[key];
    }
    return mapped;
  };
  const memoizedLowercaseProps = memoizeOne(lowercaseProps);

  return createElement('span', {
    className,
    ...rest,
    children: chunks.map((chunk, index) => {
      const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start);

      if (chunk.highlight) {
        highlightIndex++;

        let highlightClass;
        if (typeof highlightClassName === 'object') {
          if (!caseSensitive) {
            highlightClassName = memoizedLowercaseProps(highlightClassName);
            highlightClass = highlightClassName[text.toLowerCase()];
          } else {
            highlightClass = highlightClassName[text];
          }
        } else {
          highlightClass = highlightClassName;
        }

        const isActive = highlightIndex === +activeIndex;

        highlightClassNames = `${highlightClass} ${isActive ? activeClassName : ''}`;
        highlightStyles = isActive && activeStyle != null ? Object.assign({}, highlightStyle, activeStyle) : highlightStyle;

        const props = {
          children: text,
          className: highlightClassNames,
          key: index,
          style: highlightStyles,
        };

        // Don't attach arbitrary props to DOM elements; this triggers React DEV warnings (https://fb.me/react-unknown-prop)
        // Only pass through the highlightIndex attribute for custom components.
        if (typeof HighlightTag !== 'string') {
          // @ts-ignore
          props.highlightIndex = highlightIndex;
        }

        // @ts-ignore
        return createElement(HighlightTag, props);
      } else {
        return createElement('span', {
          children: text,
          className: unhighlightClassName,
          key: index,
          style: unhighlightStyle,
        });
      }
    }),
  });
};
