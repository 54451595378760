import React, { FC, useEffect, useRef } from 'react';
import { useLayout } from '@/contexts/LayoutContext';
import { Icon } from '../Icon';

type SearchInputProps = {
  value: JSX.IntrinsicElements['input']['value'];
  onChange: JSX.IntrinsicElements['input']['onChange'];
};

export const SearchInput: FC<SearchInputProps> = ({ value, onChange }) => {
  const { isSearchOpen } = useLayout();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSearchOpen) inputRef.current?.focus();
  }, [isSearchOpen]);
  return (
    <div className="relative mb-4">
      <Icon name="Search" className="absolute w-5 h-5 mx-2 my-auto top-2 bottom-2" />
      <input
        className="w-full p-2 pl-10 text-2xl border-b-2 border-gray-200 outline-none"
        data-cy="modalSearchInput"
        type="text"
        name="search"
        placeholder="Suche"
        value={value}
        onChange={onChange}
        ref={inputRef}
      />
    </div>
  );
};
