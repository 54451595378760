import { useEffect } from 'react';

const addApiTokenOnClick = () => {
  setTimeout(() => {
    const inputField = document.querySelector('#gitlab-add-pat');
    if (inputField) {
      (inputField as HTMLInputElement).value = process.env.NEXT_PUBLIC_VISUAL_REVIEWS_API_TOKEN || '';
    }
  }, 500);
};

const attachToGitlabReviewFeature = async () => {
  const nextButtonElement = document.querySelector('#gitlab-comment-button');

  // Retry if the gitlab ui is not in the dom yet
  if (!nextButtonElement) {
    setTimeout(attachToGitlabReviewFeature, 500);
    return;
  }

  nextButtonElement.addEventListener('click', addApiTokenOnClick);
};

export const useGitlabVisualReview = () => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENABLE_VISUAL_REVIEWS !== 'true') return;
    attachToGitlabReviewFeature();
  }, []);
};
