import { NavigationControlledProps, NavigationProps } from '@/components/ui/Navigation';
import { NavigationItemProps } from '@/components/ui/Navigation/Item';
import { Context, createContext } from 'react';
import { Locale } from '../LanguageContext';

export type NavigationConfig = {
  topItemMobile: Omit<NavigationItemProps, keyof NavigationControlledProps | keyof NavigationProps>;
  itemsMobile: Array<Omit<NavigationItemProps, keyof NavigationControlledProps | keyof NavigationProps>>;
  items: Array<Omit<NavigationItemProps, keyof NavigationControlledProps | keyof NavigationProps>>;
};

export type SetNavigationConfig = (fn: (prevState: NavigationConfig, t: Locale) => NavigationConfig) => void;

export type NavigationContextResult = {
  navigationConfig?: NavigationConfig;
  setNavigationConfig?: SetNavigationConfig;
};

export type NavigationContextT = Context<NavigationContextResult>;

export const NavigationContext: NavigationContextT = createContext({});
