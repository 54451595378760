import React, { FunctionComponent, useEffect, useState } from 'react';
import cn from 'classnames';

export type ToggleProps = {
  label?: string;
  name: string;
  defaultValue?: boolean;
  className?: string;
  onChange?: (value: boolean) => void;
};
export const Toggle: FunctionComponent<ToggleProps> = ({ name, defaultValue, label, className, onChange }) => {
  const [value, setValue] = useState<boolean>(defaultValue || false);

  useEffect(() => {
    setValue(defaultValue || false);
  }, [defaultValue]);

  const handleClick = (e) => {
    e.preventDefault();

    setValue((state) => {
      const newValue = !state;
      if (onChange) onChange(newValue);
      return newValue;
    });
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label
      onClick={handleClick}
      onKeyDown={handleClick}
      htmlFor={name}
      className={cn(className, 'p-1 border-2 border-black border-opacity-0 focus-within:border-opacity-100')}
    >
      {label && <span className="mr-2 cursor-pointer select-none">{label}</span>}
      <div className="Toggle">
        <input id={name} name={name} type="checkbox" className="sr-only" checked={value} readOnly={true} />
        <div className="Toggle__Track"></div>
        <div className="Toggle__Thumb"></div>
      </div>
    </label>
  );
};
