import { useLanguage } from '@/contexts/LanguageContext';
import { useLayout } from '@/contexts/LayoutContext';
import { useRouter } from 'next/router';
import { GetAllDataQuery } from 'queries/getAllData';
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Compact } from 'utils/ts-utils';
import { Icon } from '../Icon';
import { NavigationItem } from './Item';
import { NavigationItemContentHamburgerMenuLinkProps } from './Item/Content/HamburgerMenu/Link';
import cn from 'classnames';
import { useNavigationConfig } from '@/hooks/useNavigationConfig';
import useBreakpoint from '@/hooks/useBreakpoint';

export type NavigationProps = {
  hamburgerConfig: Array<NavigationItemContentHamburgerMenuLinkProps>;
  recentNews: Compact<GetAllDataQuery['allOverviewNewsArticles']>;
};

export type NavigationControlledProps = {
  closeNav: () => void;
  isClosing: boolean;
  activeNavigationMenu: string | null;
  setActiveNavigationmenu: Dispatch<SetStateAction<string | null>>;
};

export const Navigation: FC<NavigationProps> = React.memo((props) => {
  const { t } = useLanguage();
  const { navigationConfig } = useNavigationConfig();
  const router = useRouter();
  const navRef = useRef<HTMLElement>(null);
  const [isClosing, setIsClosing] = useState(false);
  const { toggleSearch } = useLayout();
  const isMobile = useBreakpoint();

  const [activeNavigationMenu, setActiveNavigationmenu] = useState<string | null>(null);

  const closeNav = () => {
    navRef.current?.classList.remove('opened');
    // unlockScrolling();
    setActiveNavigationmenu(null);
    setIsClosing(true);
  };

  // There is not onTransitionStart for react so we need to support it ourselves
  useEffect(() => {
    navRef.current?.addEventListener('transitionstart', () => {
      // Not using `activeNavigationMenu` here because it references a stale value
      const isExpanded = navRef.current?.querySelectorAll('[aria-expanded=true]').length;

      //  if (isExpanded) lockScrolling();
    });
  }, []);

  useEffect(() => {
    if (activeNavigationMenu) closeNav();
  }, [router.pathname]);

  return (
    <div className="z-20 mx-auto pointer-events-none lg:absolute lg:inset-0">
      <div
        className={cn(
          'absolute inset-0 z-10 bg-black bg-opacity-25 transition-opacity duration-300',
          activeNavigationMenu ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        )}
        role="button"
        aria-label="Navigation schließen"
        aria-describedby="Schließt die Navigationsleiste"
        tabIndex={0}
        onClick={closeNav}
        onKeyDown={closeNav}
      ></div>

      <div className="Navigation">
        <nav
          className="relative max-h-screen lg:fixed"
          aria-label="Hauptmenü"
          onTransitionEnd={() => {
            setIsClosing(false);
            if (activeNavigationMenu) {
              navRef.current?.classList.add('opened');
            }
          }}
          ref={navRef}
        >
          {isMobile && (
            <ul className="box-content flex items-center justify-around h-6 px-0 py-12 lg:hidden">
              {navigationConfig?.itemsMobile.map((menuItem) => (
                <NavigationItem
                  key={menuItem.href}
                  closeNav={closeNav}
                  isClosing={isClosing}
                  activeNavigationMenu={activeNavigationMenu}
                  setActiveNavigationmenu={setActiveNavigationmenu}
                  {...menuItem}
                  {...props}
                />
              ))}
            </ul>
          )}

          {!isMobile && (
            <ul className="box-content items-center justify-end hidden h-16 px-5 py-2 ml-8 bg-white lg:flex rounded-2xl">
              {router.pathname !== '/[lang]' && (
                <li className="relative h-full list-item">
                  <button
                    className="flex flex-col items-center w-6 h-full mr-10 justify-evenly"
                    aria-describedby={t.general.search}
                    onClick={toggleSearch}
                  >
                    <Icon name="Search" className="w-6 mt-3" />
                    <span className="mt-3">{t.general.search}</span>
                  </button>

                  <div className="absolute inset-y-0 right-0 -mt-2 -mb-1 border-r-2 border-black"></div>
                </li>
              )}

              {navigationConfig?.items.map((menuItem) => (
                <NavigationItem
                  data-cy={menuItem['data-cy']}
                  key={menuItem.href}
                  closeNav={closeNav}
                  isClosing={isClosing}
                  activeNavigationMenu={activeNavigationMenu}
                  setActiveNavigationmenu={setActiveNavigationmenu}
                  {...menuItem}
                  {...props}
                />
              ))}
            </ul>
          )}
        </nav>
      </div>
    </div>
  );
});
